import React, { createContext, FC, useContext, useState } from 'react'

type CampaignPrivateKeys = {
  [campaignId: number]: string
}

type AddCampaignPrivateKey = (
  campaignId: number,
  campaignPrivateKey: string,
) => void

type CampaignPrivateKeysContextProps = {
  campaignPrivateKeys: CampaignPrivateKeys
  addCampaignPrivateKey: AddCampaignPrivateKey
}

const initialCampaignPrivateKeys: CampaignPrivateKeys = {}

const CampaignPrivateKeysContext =
  createContext<CampaignPrivateKeysContextProps>({
    campaignPrivateKeys: initialCampaignPrivateKeys,
    addCampaignPrivateKey: () => {
      // no-op by default
    },
  })

/**
 * Provider component that wraps the app and provides a map of campaign private keys indexed by campaign ID.
 */
export const CampaignPrivateKeysProvider: FC<React.PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const [campaignPrivateKeys, setCampaignPrivateKeys] =
    useState<CampaignPrivateKeys>(initialCampaignPrivateKeys)
  const addCampaignPrivateKey: AddCampaignPrivateKey = (
    campaignId: number,
    campaignPrivateKey: string,
  ) => {
    setCampaignPrivateKeys((campaignPrivateKeys) => ({
      ...campaignPrivateKeys,
      [campaignId]: campaignPrivateKey,
    }))
  }
  return (
    <CampaignPrivateKeysContext.Provider
      value={{ campaignPrivateKeys, addCampaignPrivateKey }}
    >
      {children}
    </CampaignPrivateKeysContext.Provider>
  )
}

/**
 * Hook that returns the campaign private key in the context for the given campaign ID.
 * Returns null if the private key for the campaign is currently not stored.
 */
export const useCampaignPrivateKey = (campaignId: number): string | null => {
  const context = useContext(CampaignPrivateKeysContext)
  if (!context) {
    throw new Error(
      `useCampaignPrivateKey must be used within a CampaignPrivateKeysContext component`,
    )
  }
  const campaignPrivateKey = context.campaignPrivateKeys[campaignId]
  if (campaignPrivateKey === undefined) {
    return null
  }
  return campaignPrivateKey
}

/**
 * Hook that allows one to add a new mapping from campaign ID to campaign private key in the context.
 * Please verify that the private key for the campaign is correct before adding it to context.
 */
export const useAddCampaignPrivateKey = (): AddCampaignPrivateKey => {
  const context = useContext(CampaignPrivateKeysContext)
  if (!context) {
    throw new Error(
      `useAddCampaignPrivateKey must be used within a CampaignPrivateKeysContext component`,
    )
  }
  return context.addCampaignPrivateKey
}
