import { Transform } from 'class-transformer'
import {
  ArrayNotEmpty,
  IsArray,
  IsDefined,
  IsInt,
  IsNumber,
  IsString,
} from 'class-validator'

export enum DocumentStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
}

export class PostDocumentPublicReq {
  @IsDefined()
  @IsString()
  name: string

  @Transform(({ value }) => parseInt(value as string))
  @IsDefined()
  @IsNumber()
  sizeInBytes: number

  @IsString()
  @IsDefined()
  s3ObjectKey: string
}

export class PostDocumentPublicDto {
  id: number
}

export class PutDocumentSubmissionPublicDto {
  @IsArray()
  @ArrayNotEmpty()
  @IsInt({ each: true })
  ids: number[]
}

export class GenerateS3PresignedDataDto {
  @IsDefined()
  @IsString()
  s3ObjectKey: string

  @IsDefined()
  @IsString()
  fileType: string

  @IsDefined()
  @IsString()
  fileName: string

  @Transform(({ value }) => parseInt(value as string))
  @IsDefined()
  @IsNumber()
  fileSize: number
}

export class GetDocumentDto {
  id: number
  name: string
  s3ObjectKey: string
  extension: string
  sizeInBytes: number
  submissionId: number
  createdAt: string
  updatedAt: string
  encryptedSymmetricKey: string
  status: DocumentStatus
  isMalicious: boolean | null
}
