import { useCallback, useRef } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { BiDownload, BiUpload } from 'react-icons/bi'
import {
  FormControl,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  ModalCloseButton,
} from '@opengovsg/design-system-react'
import { useMutation } from '@tanstack/react-query'

import { GetDocumentDto } from '~shared/dtos'

import { useAdminUser } from '~/features/auth/hooks'
import { useAddCampaignPrivateKey } from '~/features/dashboard'
import { useToast } from '~/hooks/useToast'
import { decryptAndDownload, DownloadStatus } from '~/utils/download'
import { encryptionService } from '~/utils/encryption'

type DownloadModalProps = Pick<UseDisclosureReturn, 'onClose' | 'isOpen'> & {
  campaignId: number
  campaignName: string
  campaignPublicKey: string
  documents: GetDocumentDto[]
  setProgress: (progressInfo: {
    progress: number
    info: DownloadStatus
  }) => void
}

type DownloadModalFormParams = {
  privateKey: string
}

export const DownloadModal = ({
  onClose: onCloseDisclosure,
  isOpen,
  campaignId,
  campaignName,
  campaignPublicKey,
  setProgress,
  documents,
}: DownloadModalProps) => {
  const { adminUser } = useAdminUser()
  const addCampaignPrivateKey = useAddCampaignPrivateKey()
  const toast = useToast()

  const formMethods = useForm<DownloadModalFormParams>({
    defaultValues: {
      privateKey: '',
    },
    mode: 'onChange',
  })
  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setError,
    setValue,
  } = formMethods

  const onClose = useCallback(() => {
    onCloseDisclosure()
    reset()
  }, [onCloseDisclosure, reset])

  const { mutate: onSubmitMutate, isLoading } = useMutation(
    async (privateKey: string) => {
      const isPrivateKeyCorrect = encryptionService.verifyAsymmetricKey(
        campaignPublicKey,
        privateKey,
      )
      if (!isPrivateKeyCorrect) {
        throw new Error('The secret key is incorrect.')
      }
    },
    {
      onSuccess: async (_, privateKey) => {
        onClose()
        addCampaignPrivateKey(campaignId, privateKey)
        try {
          await decryptAndDownload(
            documents,
            privateKey,
            setProgress,
            adminUser?.id,
          )
        } catch (err) {
          toast({
            status: 'error',
            description:
              err instanceof Error ? err.message : 'Something went wrong.',
          })
        }
      },
      onError: (e: Error) => {
        setError('privateKey', { type: 'custom', message: e.message })
      },
    },
  )

  const onSubmit = handleSubmit(({ privateKey }) => {
    const trimmedPrivateKey = privateKey.trim()
    onSubmitMutate(trimmedPrivateKey)
  })

  const onUploadSecretKeyFile = (file: File) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = (event) => {
      const secretKeyFileText = (event.target?.result as string | null) ?? ''
      setValue('privateKey', secretKeyFileText.trim(), {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    }
  }

  const rules = {
    required: 'This field is required',
  }

  const fileInputRef = useRef<HTMLInputElement>(null)

  return (
    <FormProvider {...formMethods}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Enter or upload secret key for {campaignName}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={10} align="stretch">
              <FormControl isInvalid={!!errors.privateKey}>
                <FormLabel isRequired>
                  Your secret key was downloaded when you created your
                  collection
                </FormLabel>
                <Controller
                  name="privateKey"
                  rules={rules}
                  render={({ field: { onChange, ref, value } }) => (
                    <HStack alignItems="stretch">
                      <Input
                        ref={ref}
                        value={value}
                        placeholder="Enter secret key"
                        onChange={onChange}
                      />
                      <Input
                        ref={fileInputRef}
                        type="file"
                        accept=".txt"
                        multiple={false}
                        onChange={(e) => {
                          // Casting to allow TS to recognize this element as a file input
                          const target = e.target as HTMLInputElement
                          const file = (target.files as FileList)[0]
                          if (file !== undefined) {
                            onUploadSecretKeyFile(file)
                          }
                        }}
                        hidden
                      />
                      <IconButton
                        aria-label="Upload secret key file"
                        w="2rem"
                        size="md"
                        color="interaction.success.default"
                        bgColor="transparent"
                        borderColor="interaction.success.default"
                        _hover={{
                          color: 'interaction.success.default',
                          bgColor: 'interaction.main-subtle.default',
                          borderColor: 'interaction.success.default',
                        }}
                        _active={{
                          color: 'interaction.success.active',
                          bgColor: 'interaction.main-subtle.active',
                          borderColor: 'interaction.success.active',
                        }}
                        icon={<BiUpload fontSize="1.25rem" />}
                        onClick={(e) => {
                          e.stopPropagation()
                          fileInputRef.current?.click()
                        }}
                      />
                    </HStack>
                  )}
                />
                <FormErrorMessage>
                  {errors.privateKey?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button
              type="submit"
              onClick={() => onSubmit()}
              isLoading={isLoading}
              isDisabled={!!errors.privateKey || !isDirty}
              leftIcon={<BiDownload size={24} />}
              color="base.content.inverse"
              bgColor="interaction.main.default"
              _hover={{
                bgColor: 'interaction.main.hover',
                _disabled: { bgColor: 'interaction.support.disabled' },
              }}
              _disabled={{
                cursor: 'not-allowed',
                border: 'none',
                color: 'interaction.support.disabled-content',
                bgColor: 'interaction.support.disabled',
              }}
            >
              Download files
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  )
}
