import { BiTrash, BiXCircle } from 'react-icons/bi'
import {
  Center,
  Collapse,
  Flex,
  HStack,
  Image,
  Progress,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'

import GreenCheckSvg from '~/assets/GreenCheck.svg'
import RedExclamationMark from '~/assets/RedExclamationMark.svg'
import { fmtBytes } from '~/utils/humanReadable'

import { UploadDisplayProps } from './Upload'

export interface ButtonProps {
  onClick?: () => void
}

export const UploadCard = ({
  fileName,
  sizeInBytes,
  status,
  uploadProgress,
  dismissed,
  errorMessage,
  onCancel,
  onDismiss,
  onRemove,
}: UploadDisplayProps) => {
  const Remove = ({ onClick }: ButtonProps) => (
    <IconButton
      p={0}
      icon={<BiTrash />}
      colorScheme="red"
      aria-label="remove"
      variant="clear"
      fontSize="1.25rem"
      onClick={onClick}
    />
  )

  const Cancel = () => (
    <IconButton
      variant="clear"
      icon={<BiXCircle />}
      onClick={onCancel}
      aria-label={'cancel'}
    />
  )

  const fileSize = fmtBytes(sizeInBytes)

  const uploadedFileSize = fmtBytes(sizeInBytes * (uploadProgress / 100))

  const successfulUpload = (
    <HStack spacing={2} align="center">
      <Image src={GreenCheckSvg} aria-hidden boxSize="16px" />
      <Text textStyle="caption-2" color="secondary.500">
        {fileSize}
      </Text>
    </HStack>
  )

  const errorUpload = (
    <HStack spacing={2} align="center">
      <Image src={RedExclamationMark} aria-hidden boxSize="16px" />
      <Text textStyle="caption-2" color="red.500">
        {errorMessage}
      </Text>
    </HStack>
  )

  const loadingUpload = (
    <Text textStyle="caption-2" color="secondary.500">
      {uploadedFileSize} of {fileSize}
    </Text>
  )

  const getText = () => {
    switch (status) {
      case 'idle':
        return `Encryption queued...`
      case 'loading':
        return loadingUpload
      case 'success':
        return successfulUpload
      case 'error':
        return errorUpload
      case 'abort':
        return `Upload cancelled`
      case 'encrypting':
        return `Encrypting`
      case 'virus-scan':
        return 'Scanning file...'
    }
  }

  const getActionIcon = () => {
    switch (status) {
      case 'idle':
      case 'loading':
      case 'encrypting':
        return onCancel ? <Cancel /> : <></>
      case 'success':
        return onRemove ? (
          <Remove onClick={onRemove} />
        ) : onDismiss ? (
          <Remove onClick={onDismiss} />
        ) : (
          <></>
        )
      case 'abort':
      case 'error':
        return onDismiss ? <Remove onClick={onDismiss} /> : <></>
      case 'virus-scan':
        return <Spinner size="sm" fontSize="1.25rem" m="14px" />
      default:
        return <></>
    }
  }

  return (
    <Collapse in={!dismissed} unmountOnExit={true} animateOpacity>
      <Flex
        bg="neutral.200"
        py="0.500rem"
        px="1rem"
        borderRadius="0.5rem"
        border={`1px solid`}
        borderColor={'grey.100'}
      >
        <VStack width="100%">
          <HStack width="100%" justify="space-between">
            <VStack align="start" spacing={1}>
              <Text textStyle="file-name" color="secondary.500">
                {fileName}
              </Text>
              <Text textStyle="file-status" color="gray.500">
                {getText()}
              </Text>
            </VStack>
            <Center p={1} pr={0}>
              {getActionIcon()}
            </Center>
          </HStack>
          {status === 'loading' && (
            <Progress
              borderRadius={25}
              value={uploadProgress}
              w="100%"
              size="sm"
            />
          )}
        </VStack>
      </Flex>
    </Collapse>
  )
}
