import { useCallback, useMemo, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  Flex,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import { useAdminAuth } from '../auth'

import { useCampaign } from './hooks/campaigns.hooks'
import { useGetSubmissions } from './hooks/submissions.hooks'
import { CreateSubmissionModal } from './Submissions/CreateSubmissionModal'
import { ShareSubmissionModal } from './Submissions/ShareSubmissionModal'
import { Submissions } from './Submissions'

export const DashboardPage = (): JSX.Element => {
  const { campaign, isCampaignLoading } = useCampaign()
  const navigate = useNavigate()
  const { adminUser } = useAdminAuth()
  const { submissions } = useGetSubmissions({
    userId: adminUser?.id,
    campaignId: campaign?.id,
  })

  const title = useMemo(() => {
    const numLinks = submissions?.length ?? 0
    return `${campaign?.name ?? 'All Links'} (${numLinks})`
  }, [submissions, campaign])

  const createSubmissionDisclosure = useDisclosure()
  const shareSubmissionDisclosure = useDisclosure()
  const [shareableSubmission, setShareableSubmission] =
    useState<GetSubmissionDto | null>(null)

  const onShare = useCallback(
    (submission: GetSubmissionDto) => {
      setShareableSubmission(submission)
      shareSubmissionDisclosure.onOpen()
    },
    [shareSubmissionDisclosure],
  )

  if (isCampaignLoading) return <Spinner />

  return (
    <VStack align="stretch" spacing={10} p={6} w="full">
      <Flex justify="space-between" align="center">
        <Text textStyle="h4" noOfLines={[1, 2, 3]}>
          {title}
        </Text>
        {campaign && (
          <ButtonGroup>
            <Button
              leftIcon={<BiPlus />}
              onClick={() => createSubmissionDisclosure.onOpen()}
            >
              {`Create Submission Link`}
            </Button>
          </ButtonGroup>
        )}
      </Flex>
      <Submissions onShare={onShare} />
      {campaign && submissions && (
        <CreateSubmissionModal
          {...createSubmissionDisclosure}
          campaign={campaign}
          submissions={submissions}
          onCreate={onShare}
        />
      )}
      {shareableSubmission && (
        <ShareSubmissionModal
          {...shareSubmissionDisclosure}
          submission={shareableSubmission}
        />
      )}
    </VStack>
  )
}
