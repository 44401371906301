import CharacterCount from '@tiptap/extension-character-count'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import { Extensions } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import { MAX_CHAR_COUNT } from './constants'

export const extensions: Extensions = [
  StarterKit.configure({ code: false, horizontalRule: false }),
  Link,
  CharacterCount.configure({ limit: MAX_CHAR_COUNT }),
  Underline,
  Image,
]
