export const routes = {
  index: '/',
  public: {
    index: '/public',
    error: '/public/error',
  },
  collections: 'collections/:campaignId',
  admin: {
    index: '/admin',
    login: '/admin/login',
  },
}
