import { useEffect, useRef } from 'react'
import { UseToastOptions } from '@chakra-ui/react'

import { useToast } from '~/hooks/useToast'

import { UploadDisplayProps } from './Upload'

export const UploadToast = ({ fileName, status }: UploadDisplayProps) => {
  const toastIdRef = useRef<string | number>('')
  const toast = useToast()

  const getToastDisplay = (status: string): UseToastOptions | null => {
    switch (status) {
      case 'idle':
        return null
      case 'loading':
        return null
      case 'success':
        return {
          status: 'success',
          description: `${fileName} has been successfully uploaded`,
        }
      case 'error':
        return {
          status: 'error',
          description: `Failed to upload ${fileName}`,
        }
      case 'abort':
        return {
          status: 'error',
          description: `Upload of ${fileName} has been cancelled`,
        }
      case 'removed':
        return {
          status: 'success',
          description: `${fileName} has been successfully deleted`,
        }
    }
    return null
  }

  useEffect(() => {
    const toastDisplay = getToastDisplay(status)
    if (!toastIdRef.current && toastDisplay) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      toastIdRef.current = toast(toastDisplay)!
    } else if (toastDisplay && !toast.isActive(toastIdRef.current)) {
      toast(toastDisplay)!
    } else if (toastDisplay) {
      toast.update(toastIdRef.current, toastDisplay)
    }
  }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

  return <></>
}
