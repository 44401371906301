import { Navigate, RouteObject } from 'react-router-dom'

import { routes } from '~/constants/routes'
import { AdminProtectedRoute, LoginPage } from '~/features/auth'
import { DashboardPage } from '~/features/dashboard'
import { SubmissionPage } from '~/features/dashboard/SubmissionPage'
import { AdminLayout } from '~/layouts/AdminLayout'

export const adminRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="collections" />,
  },
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'collections',
    element: (
      <AdminProtectedRoute>
        <AdminLayout />
      </AdminProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
    ],
  },
  {
    path: routes.collections,
    element: (
      <AdminProtectedRoute>
        <AdminLayout />
      </AdminProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: 'submission/:submissionId',
        element: <SubmissionPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={routes.collections} />,
  },
]
