import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TagInput } from '@opengovsg/design-system-react'
import { isEmail } from 'class-validator'

export interface MultiEmailInputProps {
  name: string
  isDisabled?: boolean
}

export const MultiEmailInput = ({
  name,
  isDisabled = false,
}: MultiEmailInputProps): JSX.Element => {
  const { control } = useFormContext<Record<string, string[]>>()
  const tagValidation = useMemo(() => isEmail, [])

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: {
          valid: (emails: string[]) =>
            emails.filter(Boolean).every((e) => isEmail(e)),
        },
      }}
      render={({ field }) => (
        <TagInput
          {...field}
          tagValidation={tagValidation}
          isDisabled={isDisabled}
          tagColorScheme="green"
        />
      )}
    />
  )
}
