import { ReactNode, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Center, FormControl } from '@chakra-ui/react'
import { Attachment, FormErrorMessage } from '@opengovsg/design-system-react'

import { fmtBytes } from '~/utils/humanReadable'

export interface AttachmentFieldProps {
  /**
   * Function to call when user submits a file.
   */
  onSubmit: (file: File) => Promise<void>

  /**
   * Maximum file size in bytes
   */
  maxSizeInBytes?: number

  /**
   * List of comma separated file extensions to accept
   */
  accept?: string

  /**
   * Max filename length (including extension)
   */
  maxFileNameLen?: number

  /**
   * Regex to test filename against.
   */
  fileNameRegex?: RegExp

  /**
   * Error to display if filename is invalid.
   */
  fileNameRegexErrorMsg?: string
}

/**
 * Attachment field that automatically submits on drop
 */
export const AttachmentField = ({
  onSubmit,
  maxSizeInBytes,
  accept,
  maxFileNameLen,
  fileNameRegex = /^[\w\-()!. ]+\.\w+$/,
  fileNameRegexErrorMsg,
}: AttachmentFieldProps) => {
  const name = 'fileInput'
  const {
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
    formState,
    formState: { errors, isValidating, submitCount },
  } = useForm({ mode: 'onChange' })

  const isInvalid = !!errors?.[name]

  const handleOnSubmit = handleSubmit(async (values: Record<string, File>) => {
    const file = values[name]
    setValue(name, undefined)
    await onSubmit(file)
  })

  useEffect(() => {
    if (
      formState.isValid &&
      !isValidating &&
      !formState.isSubmitting &&
      getValues(name) !== undefined
    ) {
      void handleOnSubmit()
    }
  }, [formState, getValues, isValidating]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form>
      <FormControl isInvalid={isInvalid}>
        <Controller
          render={({ field: { onChange, ...rest } }) => (
            <Attachment
              key={submitCount}
              {...rest}
              onChange={(file) => {
                clearErrors(name)
                onChange(file)
              }}
              onError={(message: string) => {
                setError(name, { message })
              }}
              accept={accept}
              isInvalid={isInvalid}
            />
          )}
          name={name}
          rules={{
            validate: {
              validateFilename: (v: File) =>
                v === undefined
                  ? true
                  : fileNameRegex.test(v.name) ||
                    (fileNameRegexErrorMsg ?? 'Invalid Filename'),
              validateFilenameLength: (v: File) =>
                v === undefined || maxFileNameLen === undefined
                  ? true
                  : v.name.length < maxFileNameLen ||
                    `Filename too long (maximum ${maxFileNameLen} characters)`,
              validateFileSize: (v: File) =>
                v === undefined || !maxSizeInBytes
                  ? true
                  : v.size <= maxSizeInBytes ||
                    `File size exceeds limit of ${fmtBytes(maxSizeInBytes)}`,
            },
          }}
          control={control}
        />
        <FormErrorMessage>
          <Center>{errors[name]?.message as ReactNode}</Center>
        </FormErrorMessage>
      </FormControl>
    </form>
  )
}
