import { useCallback } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react'
import {
  DatePicker,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  ModalCloseButton,
} from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import {
  FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS,
  SUBMISSION_ID_REGEX,
  SUBMISSION_ID_REGEX_ERROR,
  SUBMISSION_MAX_ID_LEN,
  SUBMISSION_MAX_ID_LEN_ERROR,
} from '~shared/constants/submissions'
import {
  CreateSubmissionReq,
  GetCampaignDto,
  GetSubmissionDto,
} from '~shared/dtos'
import { isSubmissionLinkExpiryDateValid } from '~shared/utils/submissions'

import { RichTextEditor } from '~/features/richtext'
import { useToast } from '~/hooks/useToast'

import { useCreateSubmissionMutation } from '../hooks/submissions.hooks'

type CreateSubmissionModalProps = UseDisclosureReturn & {
  campaign: GetCampaignDto
  submissions: GetSubmissionDto[]
  onCreate: (submission: GetSubmissionDto) => void
}

export const CreateSubmissionModal = ({
  onClose: onCloseDisclosure,
  isOpen,
  onCreate,
  campaign,
  submissions,
}: CreateSubmissionModalProps) => {
  const toast = useToast()
  const formMethods = useForm<CreateSubmissionReq>({
    mode: 'onChange',
    defaultValues: {
      instructions: campaign.defaultInstructions,
      expiresAt: dayjs().add(1, 'week').startOf('day').toDate(),
    },
  })
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isDirty },
  } = formMethods

  const onClose = useCallback(() => {
    onCloseDisclosure()
    reset()
  }, [onCloseDisclosure, reset])

  const createSubmissionMutation = useCreateSubmissionMutation(
    campaign?.userId,
    {
      onSuccess: (res: GetSubmissionDto) => {
        onClose()
        toast({
          status: 'success',
          description: 'Link created',
        })
        onCreate(res)
      },
      onError: () => {
        toast({
          status: 'error',
          description:
            'There was an error creating the link. Please try again later.',
        })
      },
    },
  )

  const onSubmit = handleSubmit((values) => {
    createSubmissionMutation.mutate({
      ...values,
      campaignId: campaign.id,
      internalId: values.internalId.trim(),
      // name: values.name?.trim() || undefined, // set `name` to undefined if it's an empty string
    })
    reset()
  })

  return (
    <FormProvider {...formMethods}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Submission Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={10} align="stretch">
              <FormControl isRequired isInvalid={!!errors.internalId}>
                <FormLabel>{'Unique identifier'}</FormLabel>
                <FormHelperText mb={2}>
                  This may be a UIN or Case ID. Identifier will be used to
                  organize your incoming documents.
                </FormHelperText>
                <Controller
                  name="internalId"
                  rules={{
                    required: 'Please enter an identifier',
                    maxLength: {
                      value: SUBMISSION_MAX_ID_LEN,
                      message: SUBMISSION_MAX_ID_LEN_ERROR,
                    },
                    validate: {
                      pattern: (value: string) => {
                        if (!SUBMISSION_ID_REGEX.test(value.trim()))
                          return SUBMISSION_ID_REGEX_ERROR
                        if (
                          submissions
                            .map((submission) => submission.internalId)
                            .includes(value.trim())
                        )
                          return 'A submission with this unique identifier already exists'
                      },
                    },
                  }}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      ref={ref}
                      value={value}
                      placeholder="Enter UIN or Case ID"
                      onChange={onChange}
                      maxLength={SUBMISSION_MAX_ID_LEN}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.internalId?.message}
                </FormErrorMessage>
              </FormControl>

              {/* <FormControl isInvalid={!!errors.name}>
                <FormLabel>{'Name'}</FormLabel>
                <Controller
                  name="name"
                  rules={{
                    maxLength: {
                      value: SUBMISSION_MAX_NAME_LEN,
                      message: SUBMISSION_MAX_NAME_LEN_ERROR,
                    },
                    validate: {
                      pattern: (value?: string) => {
                        if (!value) return true
                        if (!SUBMISSION_NAME_REGEX.test(value.trim()))
                          return SUBMISSION_NAME_REGEX_ERROR
                      },
                    },
                  }}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      ref={ref}
                      value={value}
                      placeholder="Enter name"
                      onChange={onChange}
                    />
                  )}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl> */}

              <FormControl isRequired isInvalid={!!errors.expiresAt}>
                <FormLabel>{'Link expiry date'}</FormLabel>
                <FormHelperText>
                  The link will be open for file submissions till this date
                  (inclusive). Once the submission link has expired, you will
                  not be able to change this date.
                </FormHelperText>
                <HStack mb={2} spacing="0.25rem">
                  <WarningTwoIcon color="utility.feedback.critical" />
                  <FormHelperText textColor="utility.feedback.critical">
                    All files collected on this link will be deleted after{' '}
                    {FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS} days of this date.
                  </FormHelperText>
                </HStack>
                <Controller
                  name="expiresAt"
                  control={control} // Needed to recognise value as Date
                  rules={{
                    required: 'Please enter a date',
                    validate: {
                      pattern: (date: Date) => {
                        if (!isSubmissionLinkExpiryDateValid(date))
                          return 'Date cannot be in the past'
                      },
                    },
                  }}
                  render={({ field: { value } }) => (
                    <DatePicker
                      onChange={(date) => {
                        setValue('expiresAt', date ?? value, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }}
                      value={value}
                      name={'expiry-datepicker'}
                      isDateUnavailable={(date) =>
                        !isSubmissionLinkExpiryDateValid(date)
                      }
                    />
                  )}
                />
                <FormErrorMessage>{errors.expiresAt?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.instructions}>
                <FormLabel>{'Instructions'}</FormLabel>
                <FormHelperText mb={2}>
                  Include your requirements or any other information you would
                  like the receiver to know here.
                </FormHelperText>
                <Controller
                  name="instructions"
                  render={({ field: { value } }) => (
                    <RichTextEditor
                      content={value as string}
                      setContent={(c) =>
                        setValue('instructions', c, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.instructions?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={8}
              variant="clear"
              colorScheme="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSubmit()
                reset()
              }}
              isDisabled={
                // !!errors.name ||
                !!errors.internalId ||
                !!errors.expiresAt ||
                !!errors.instructions ||
                createSubmissionMutation.isLoading ||
                !isDirty
              }
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  )
}
