import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  ModalCloseButton,
} from '@opengovsg/design-system-react'

import {
  CAMPAIGN_MAX_NAME_LEN,
  CAMPAIGN_MAX_NAME_LEN_ERROR,
  CAMPAIGN_NAME_REGEX,
  CAMPAIGN_NAME_REGEX_ERROR,
} from '~shared/constants/campaigns'
import { CreateCampaignReq, GetCampaignDto } from '~shared/dtos'

import { useAdminAuth } from '~/features/auth'
import { useCreateCampaignMutation } from '~/features/dashboard/hooks/campaigns.hooks'
import { useToast } from '~/hooks/useToast'
import { ResponseError } from '~/types/error'
import { encryptionService } from '~/utils/encryption'

import { CampaignSecretKeyModal } from './CampaignSecretKeyModal'

interface CreateCampaignModalProps {
  onClose: () => void
  isOpen: boolean
}

export const CreateCampaignModal = ({
  onClose,
  isOpen,
}: CreateCampaignModalProps) => {
  const { adminUser } = useAdminAuth()
  const navigate = useNavigate()
  const toast = useToast()
  const keyPair = useRef(encryptionService.generateAsymmetricKeyPair())

  const {
    setError,
    formState: { errors, isDirty },
    register,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm<CreateCampaignReq>({
    defaultValues: {
      name: '',
      defaultInstructions: '',
      publicKey: '',
    },
    mode: 'onChange',
  })

  const handleClose = () => {
    reset()
    keyPair.current = encryptionService.generateAsymmetricKeyPair()
    onClose()
  }

  const createCampaignMutation = useCreateCampaignMutation(adminUser?.id, {
    onSuccess: (res: GetCampaignDto) => {
      toast({
        status: 'success',
        description: 'Your collection has been created.',
      })
      navigate(`/admin/collections/${res.id}`)
    },
    onError: (err: ResponseError) => {
      setError('name', {
        type: 'custom',
        message: err.json.message,
      })
    },
  })

  const {
    isOpen: isOpenSecretKeyModal,
    onOpen: onOpenSecretKeyModal,
    onClose: onCloseSecretKeyModal,
  } = useDisclosure()

  const handleSubmit = () => {
    setValue('publicKey', keyPair.current.publicKey)
    setValue('name', getValues('name').trim())
    createCampaignMutation.mutate(getValues())
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Collection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={10} align="stretch">
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>{'Collection Name'}</FormLabel>
              <Input
                placeholder="Enter name"
                maxLength={CAMPAIGN_MAX_NAME_LEN}
                {...register('name', {
                  required: 'Please enter a collection name',
                  maxLength: {
                    value: CAMPAIGN_MAX_NAME_LEN,
                    message: CAMPAIGN_MAX_NAME_LEN_ERROR,
                  },
                  validate: (value) => {
                    if (!CAMPAIGN_NAME_REGEX.test(value.trim()))
                      return CAMPAIGN_NAME_REGEX_ERROR
                  },
                })}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              <FormHelperText>
                {`${CAMPAIGN_MAX_NAME_LEN - (watch('name')?.length || 0)}
                    characters left`}
              </FormHelperText>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleClose}
            mr={8}
            variant="clear"
            colorScheme="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={onOpenSecretKeyModal}
            isDisabled={
              !!errors.name || createCampaignMutation.isLoading || !isDirty
            }
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
      <CampaignSecretKeyModal
        onClose={() => {
          onCloseSecretKeyModal()
          handleClose()
        }}
        isOpen={isOpenSecretKeyModal}
        campaignName={getValues('name').trim()}
        submitForm={handleSubmit}
        privateKey={keyPair.current.privateKey}
      />
    </Modal>
  )
}
