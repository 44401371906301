import { useCallback, useEffect, useMemo, useState } from 'react'
import { BiDownload } from 'react-icons/bi'
import {
  Box,
  Button,
  chakra,
  Checkbox,
  Code,
  HStack,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'
import {
  BxMailSend,
  BxRightArrowAlt,
  IconButton,
} from '@opengovsg/design-system-react'
import dedent from 'dedent'

import { ReactComponent as WarningSign } from '~/assets/WarningSign.svg'

interface CampaignSecretKeyModalProps {
  onClose: () => void
  isOpen: boolean
  campaignName: string
  submitForm: () => void
  privateKey: string
}

const WarningIcon = chakra(WarningSign)

export const CampaignSecretKeyModal = ({
  onClose,
  isOpen,
  campaignName,
  submitForm,
  privateKey,
}: CampaignSecretKeyModalProps) => {
  const [isCheckedItem, setCheckedItem] = useState<boolean>(false)
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false)
  const { hasCopied, onCopy, setValue } = useClipboard(privateKey)

  useEffect(() => {
    setValue(privateKey)
  }, [privateKey, setValue])

  const onDownloadKey = useCallback(() => {
    const blob = new Blob([privateKey], {
      type: 'text/plain;charset=utf-8',
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = `Highway Collection Secret Key - ${campaignName}`
    link.href = url
    link.click()
    setIsDownloaded(true)
  }, [campaignName, privateKey])

  const mailToHref = useMemo(() => {
    const subject = ` Secret Key for ${campaignName} Collection`
    const body = dedent`
        Dear Collaborator,

        I am sharing my collection's secret key with you for safekeeping and backup. This is an important key that is needed to access all collection submissions.

        Collection Name: ${campaignName}

        Secret Key: ${privateKey}

        All you need to do is keep this email as a record, and please do not share this key with anyone else.

        Thank you for helping to safekeep my collection!`
    const href = `mailto:?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`
    return href
  }, [campaignName, privateKey])

  const handleClose = () => {
    setCheckedItem(false)
    setIsDownloaded(false)
    onClose()
  }

  const handleSubmit = () => {
    submitForm()
    handleClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <Box
          mx="8"
          my="6"
          p="24px"
          gap="24px"
          border="1px"
          borderColor="base.divider.medium"
          display="flex"
          flexDir="column"
        >
          <WarningIcon />
          <Text textStyle="h4">Download Secret Key to Proceed</Text>
          <Text as="span">
            You’ll need it every time you access your responses to this
            collection. If you lose it,{' '}
            <Text color="interaction.critical.default" as="span">
              all responses will be permanently lost.
            </Text>
            <br />
            <br />
            You can also{' '}
            <Link href={mailToHref} isExternal>
              email it
            </Link>{' '}
            for safekeeping.
          </Text>
          <HStack justifyContent="space-between">
            <Tooltip mt={0} label={hasCopied ? 'Copied!' : 'Copy key'}>
              <Code
                bg="interaction.sub-subtle.default"
                border="1px"
                borderColor="base.divider.strong"
                py="10px"
                px="2"
                cursor="pointer"
                _hover={{
                  bg: 'interaction.sub-subtle.hover',
                }}
                onClick={onCopy}
                isTruncated={true}
                minW="fit-content"
                fontSize="sm"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {privateKey}
              </Code>
            </Tooltip>
            <Button rightIcon={<BiDownload />} onClick={onDownloadKey}>
              Download
            </Button>
            <IconButton
              aria-label="mail-key"
              icon={<BxMailSend />}
              variant="outline"
              onClick={() => window.open(mailToHref)}
            />
          </HStack>
        </Box>
        {isDownloaded && (
          <Box mx="8" mb="6" gap="24px" display="flex">
            <Checkbox
              aria-label="Private key acknowledgement"
              onChange={(e) => setCheckedItem(e.target.checked)}
              size="sm"
              isFocusable={false}
              _focusWithin={{ outline: 'none' }}
            >
              If I lose my Secret Key, I cannot activate my collection or access
              any responses to it
            </Checkbox>
          </Box>
        )}
        <Box mx="8" mb="6" gap="24px" display="flex">
          <Button
            w="full"
            rightIcon={<BxRightArrowAlt />}
            onClick={handleSubmit}
            isDisabled={!isCheckedItem}
          >
            I have saved my secret key safely
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  )
}
