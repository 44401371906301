import { PropsWithChildren, Suspense } from 'react'
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { ScrollToTop } from '~/components/ScrollToTop'
import { AdminAuthProvider } from '~/features/auth'
import { CampaignPrivateKeysProvider } from '~/features/dashboard'
import { LandingPage } from '~/features/landing/LandingPage'
import { PrivacyPolicyPage } from '~/features/privacy-policy'
import { AppLayout } from '~/layouts/AppLayout'

import { adminRoutes } from './admin.routes'
import { userRoutes } from './user.routes'

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: (
          <AdminAuthProvider>
            <LandingPage />
          </AdminAuthProvider>
        ),
      },
      {
        path: 'admin',
        element: (
          <AdminAuthProvider>
            <CampaignPrivateKeysProvider>
              <Outlet />
            </CampaignPrivateKeysProvider>
          </AdminAuthProvider>
        ),
        children: adminRoutes,
      },
      ...userRoutes,
      {
        path: 'privacy',
        element: (
          <ScrollToTop>
            <PrivacyPolicyPage />
          </ScrollToTop>
        ),
      },
      {
        path: '*',
        element: <Navigate to="admin" />,
      },
    ],
  },
])

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    {children}
  </Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <RouterProvider router={router} />
    </WithSuspense>
  )
}
