export const IS_DEVELOPMENT_ENV =
  (process.env.REACT_APP_ENV || process.env.NODE_ENV) === 'development'

export const HOSTNAME_WITH_PROTOCOL = IS_DEVELOPMENT_ENV
  ? `http://localhost:3000`
  : `https://${window.location.hostname}`

export const APP_NAME = 'Highway'

export const MAX_CONCURRENT_DOWNLOADS = 5
export const MAX_DOWLOAD_FILE_SIZE_IN_BYTES = 1073741824 // 1 GB
