import { useRef, useState } from 'react'

import { generateS3ObjectKey } from '~/utils/fileUploads'

import {
  useGeneratePresignedPostUrl,
  useSubmissionPublicId,
  useUploadAndCreateDocument,
} from '../public/public.hooks'

export type UploadStatus =
  | 'idle'
  | 'loading'
  | 'success'
  | 'error'
  | 'abort'
  | 'encrypting'
  | 'removed'
  | 'virus-scan'

interface XhrErrorResponse {
  statusCode: number
  message: string
  error: string
}

export interface UploadFunctionProps {
  /**
   * File object to upload
   */
  file: File | Blob

  /**
   * Properties to attach to body of upload request
   */
  props: {
    name: string
    sizeInBytes: number
    [key: string]: unknown
  }

  /**
   * Function to run on complete of upload.
   */
  onComplete?: (completion: {
    key: string
    uploadStatus: UploadStatus
  }) => Promise<void> | void

  /**
   * Encryption key
   */
  publicKey: string
}

export interface UploadInfo<TResponse> {
  /**
   * Filename of file object
   */
  fileName: string

  /**
   * Size of file object
   */
  sizeInBytes: number
  /**
   * Function to abort upload
   */
  abort: () => void

  /**
   * Upload progress, 0-100
   */
  uploadProgress: number

  /**
   * Status of upload
   */
  status: UploadStatus

  /**
   * Error object
   */
  error?: { status: number; message: string; error: string }

  /**
   * Response object, whatever upload returns
   */
  info?: TResponse
}

export interface UploadHookInfo<TResponse> {
  /**
   * Async upload mutation to add to queue
   */
  queueUpload: (props: UploadFunctionProps) => string

  /**
   * Results of uploads
   */
  infos: Record<string, UploadInfo<TResponse>>

  setInfo: (key: string, info: Partial<UploadInfo<TResponse>>) => void
}

export interface UploadProps {
  /**
   * True if we want sequential uploads, otherwise all upload run in parallel
   */
  sequentialUploads?: boolean
}

// Hook for uploading a file, with progress indicator
// Based off https://javascript.info/resume-upload and https://javascript.info/xmlhttprequest
// Fetch API still has not implemented streaming body, hence XHR is used here
// TODO: Find out if there's a cleaner way to implement enqueuer hooks
export const useUpload = <TResponse,>({
  sequentialUploads = true,
}: UploadProps): UploadHookInfo<TResponse> => {
  const count = useRef(0) // This is used as a key to track
  const completedCount = useRef(0) // This is used to track of completed for the queue
  const { submissionPublicId } = useSubmissionPublicId()
  const generateS3PresignedDataMutation = useGeneratePresignedPostUrl({
    submissionPublicId,
  })
  const createDocument = useUploadAndCreateDocument({
    submissionPublicId,
  })
  const [infos, setInfos] = useState<Record<string, UploadInfo<TResponse>>>({})
  const infosRef = useRef(infos)
  infosRef.current = infos

  const queueUpload = (uploadProps: UploadFunctionProps) => {
    count.current += 1
    const current = count.current // this goes into promise
    const key = `${count.current}`

    const xhr = new XMLHttpRequest()
    const info: UploadInfo<TResponse> = {
      fileName: uploadProps.props.name,
      sizeInBytes: uploadProps.props.sizeInBytes,
      abort: () => {
        setInfo(key, {
          status: 'abort',
          error: {
            message: 'Aborted',
            status: xhr.status,
            error: xhr.statusText,
          },
        })
        completedCount.current += 1
      },
      status: 'idle',
      uploadProgress: 0,
    }

    setInfos((infos) => {
      return { ...infos, [key]: info }
    })

    // Queue upload 1 at a time
    if (sequentialUploads && count.current === 1) {
      void upload({ key, uploadProps, xhr })
    } else {
      void new Promise<void>((resolve) => {
        // Wait for previous upload to complete
        const timer = setInterval(() => {
          // Wait until the total completed is one less than current
          if (completedCount.current >= current - 1) {
            clearInterval(timer)
            resolve()
          }
        }, 1000)
      }).then(() => upload({ key, uploadProps, xhr }))
    }

    return key
  }

  // TODO convert to useReducer
  const setInfo = (key: string, info: Partial<UploadInfo<TResponse>>) => {
    setInfos((infos) => {
      return {
        ...infos,
        [key]: {
          ...infos[key],
          ...info,
        },
      }
    })
  }

  // Actual upload function
  const upload = async ({
    key,
    uploadProps,
    xhr,
  }: {
    key: string
    uploadProps: UploadFunctionProps
    xhr: XMLHttpRequest
  }) => {
    // Check for early abort
    if (infosRef.current[key] && infosRef.current[key].status === 'abort') {
      return
    }
    const { props, onComplete } = uploadProps

    // create s3 object key and get presigned url
    const s3ObjectKey = generateS3ObjectKey(uploadProps.props.name)
    const { url: presignedUrl, fields } =
      await generateS3PresignedDataMutation.mutateAsync(
        {
          s3ObjectKey,
          fileType: uploadProps.file.type,
          fileName: uploadProps.props.name,
          fileSize: uploadProps.props.sizeInBytes,
        },
        {
          onError: () => {
            setInfo(key, {
              status: 'error',
              error: {
                message: 'File upload failed, please try again.',
                status: xhr.status,
                error: xhr.statusText,
              },
            })
            if (onComplete) {
              void onComplete({
                key,
                uploadStatus: 'error',
              })
            }
            completedCount.current += 1
            return
          },
        },
      )

    // create formdata and set loading
    const formdata = new FormData()
    Object.entries(fields).forEach(([key, value]) =>
      formdata.append(key, value),
    )
    formdata.append('file', uploadProps.file)

    setInfo(key, {
      status: 'loading',
      uploadProgress: 0,
      abort: () => xhr.abort(),
    })
    return new Promise<void>((resolve) => {
      xhr.onload = async () => {
        // Onload is called when client UPLOAD portion is successful
        // Still need to get the server response and parse it based on status
        // as server might reject
        if (xhr.status === 200 || xhr.status === 204) {
          setInfo(key, { status: 'virus-scan', uploadProgress: 100 })
          // save to DB
          const document = await createDocument.mutateAsync(
            {
              name: uploadProps.props.name,
              sizeInBytes: uploadProps.file.size,
              s3ObjectKey,
            },
            {
              onError: (error) => {
                setInfo(key, {
                  status: 'error',
                  error: {
                    message: 'Virus scan failed, please try uploading again.',
                    status: xhr.status,
                    error: xhr.statusText,
                  },
                })
                if (onComplete) {
                  void onComplete({
                    key,
                    uploadStatus: 'error',
                  })
                }
                resolve()
              },
            },
          )
          setInfo(key, {
            status: 'success',
            info: document as TResponse,
          })
          if (onComplete) {
            void onComplete({
              key,
              uploadStatus: 'success',
            })
          }
        } else {
          // All other codes are invalid
          setInfo(key, {
            status: 'error',
            error: {
              message: (xhr.response as XhrErrorResponse).message,
              status: xhr.status,
              error: xhr.statusText,
            },
          })
          if (onComplete)
            void onComplete({
              key,
              uploadStatus: 'error',
            })
        }
        resolve()
      }

      xhr.onabort = () => {
        setInfo(key, {
          status: 'abort',
          error: {
            message: 'Aborted',
            status: xhr.status,
            error: xhr.statusText,
          },
        })
        if (onComplete)
          void onComplete({
            key,
            uploadStatus: 'error',
          })
        resolve()
      }

      xhr.ontimeout = () => {
        setInfo(key, {
          status: 'error',
          error: {
            message: 'Upload timed out',
            status: xhr.status,
            error: xhr.statusText,
          },
        })
        if (onComplete)
          void onComplete({
            key,
            uploadStatus: 'error',
          })
        resolve()
      }

      xhr.onerror = (_e: ProgressEvent<EventTarget>) => {
        setInfo(key, {
          status: 'error',
          error: {
            message: (xhr.response as XhrErrorResponse).message,
            status: xhr.status,
            error: xhr.statusText,
          },
        })
        if (onComplete)
          void onComplete({
            key,
            uploadStatus: 'error',
          })
        resolve()
      }

      xhr.upload.onprogress = (e: ProgressEvent<EventTarget>) => {
        if (e.loaded <= props.sizeInBytes) {
          const percent = Math.round((e.loaded / props.sizeInBytes) * 100)
          setInfo(key, { uploadProgress: Math.max(percent - 1, 0) })
        }

        if (e.loaded == e.total) {
          setInfo(key, { uploadProgress: 99 })
        }
      }

      xhr.responseType = 'json'
      xhr.open('POST', presignedUrl, true)
      xhr.timeout = 1000 * 60 * 30 // time in milliseconds
      xhr.send(formdata)
    }).then(() => (completedCount.current += 1)) // increment completed count
  }

  return {
    queueUpload,
    infos,
    setInfo,
  }
}
