import { useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { Editor } from '@tiptap/react'

import { MAX_CHAR_COUNT } from './constants'

export const CharacterCountView = ({ editor }: { editor: Editor }) => {
  const storage = editor.storage as {
    characterCount: { characters: () => number }
  }
  const numChars = storage.characterCount.characters()

  const remainingChars = useMemo(() => MAX_CHAR_COUNT - numChars, [numChars])

  return (
    <>
      <Text
        textStyle="body-2"
        color={remainingChars === 0 ? 'red.500' : undefined}
        alignSelf="flex-end"
      >
        {`${remainingChars} characters left`}
      </Text>
    </>
  )
}
