export const adminQueryKeys = {
  base: 'admin' as const,
  announcementBanner: ['announcement-banner'] as const,
  id: (id?: number) => [adminQueryKeys.base, id] as const,
  campaigns: (id?: number) => [...adminQueryKeys.id(id), 'campaigns'] as const,
  submissions: ({
    userId,
    campaignId,
  }: {
    userId?: number
    campaignId?: number
  }) => {
    return campaignId
      ? ([...adminQueryKeys.id(userId), 'submissions', campaignId] as const)
      : ([...adminQueryKeys.id(userId), 'submissions'] as const)
  },
}

export const publicQueryKeys = {
  base: 'public' as const,
  submission: (submissionPublicId: string) =>
    [publicQueryKeys.base, submissionPublicId] as const,
}
