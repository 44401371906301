import pLimit from 'p-limit'

import { GetDocumentDto } from '~shared/dtos'

import {
  MAX_CONCURRENT_DOWNLOADS,
  MAX_DOWLOAD_FILE_SIZE_IN_BYTES,
} from '~/constants/config'
import { api } from '~/lib/api'
import { encryptionService } from '~/utils/encryption'

export const decryptAndDownload = async (
  documents: GetDocumentDto[],
  campaignPrivateKey: string,
  onProgress: (progressInfo: {
    progress: number
    info: DownloadStatus
  }) => void,
  adminUserId?: number,
) => {
  const documentsSize = documents.reduce(
    (total, doc) => total + doc.sizeInBytes,
    0,
  )

  // if file size more than max download size, then
  // we download files sequentially, set limit to 1
  const limit = pLimit(
    documentsSize >= MAX_DOWLOAD_FILE_SIZE_IN_BYTES
      ? 1
      : MAX_CONCURRENT_DOWNLOADS,
  )
  let totalDownloadedSize = 0
  return Promise.all(
    documents.map((doc) =>
      limit(async () => {
        onProgress({ progress: 0, info: DownloadStatus.INITIALIZING })
        const symmetricKey = encryptionService.decryptKeyAsymmetrically(
          campaignPrivateKey,
          doc.encryptedSymmetricKey,
        )
        if (symmetricKey === null) {
          onProgress({ progress: 0, info: DownloadStatus.FAILED })
          throw new Error("Could not decrypt the document's symmetric key.")
        }
        try {
          // Fetch document with Wretch and track progress
          const response = await api
            .get(`/users/${adminUserId ?? ''}/documents/${doc.id}`)
            .res()

          if (!response.ok) {
            onProgress({ progress: 0, info: DownloadStatus.FAILED })
            return
          }

          const reader = response.body?.getReader()
          const chunks: Uint8Array[] = []

          // Read the file in chunks and track progress
          while (reader) {
            const { done, value } = await reader.read()
            if (done) break
            chunks.push(value)
            totalDownloadedSize += value.length

            // Calculate and send progress as percentage of total download size
            const totalProgress = Math.round(
              Math.min(totalDownloadedSize / documentsSize, 0.98) * 100,
            )
            onProgress({
              progress: totalProgress,
              info: DownloadStatus.DOWNLOADING,
            }) // Update progress using the callback
          }

          // Combine all chunks into a single Blob for decryption
          onProgress({ progress: 99, info: DownloadStatus.DECRYPTING })
          const encryptedBlob = new Blob(chunks)
          const decryptedFile =
            await encryptionService.decryptFileSymmetrically(
              symmetricKey,
              encryptedBlob,
            )

          onProgress({ progress: 100, info: DownloadStatus.COMPLETED })
          const downloadLink = document.createElement('a')
          downloadLink.download = doc.name
          downloadLink.href = URL.createObjectURL(decryptedFile)
          downloadLink.click()
        } catch (error) {
          onProgress({ progress: 0, info: DownloadStatus.FAILED })
        }
      }),
    ),
  )
}

export enum DownloadStatus {
  INITIALIZING = 'INITIALIZING',
  DOWNLOADING = 'DOWNLOADING',
  DECRYPTING = 'DECRYPTING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
