import { shadows } from './shadows'
import { sizes } from './sizes'
import { spacing } from './spacing'
import { typography } from './typography'

export const foundations = {
  sizes,
  shadows,
  spacing,
  typography,
}
