import { Outlet } from 'react-router-dom'
import { Box, Flex, Image, VStack } from '@chakra-ui/react'

import { AppFooter } from '~/app/AppFooter'
import LogoSvg from '~/assets/Logo.svg'

export const PublicLayout = () => {
  return (
    <VStack width="100%" align="stretch" spacing={0}>
      <Flex
        position="static"
        pos="relative"
        flexDir="row"
        px={6}
        py={7}
        justifyContent="space-between"
        width="100%"
        align="center"
      >
        <Image maxW="8rem" src={LogoSvg} />
      </Flex>
      <VStack minH="admin-content-min-height">
        <Outlet />
      </VStack>
      <AppFooter />
    </VStack>
  )
}
