import { BiLeftArrowAlt } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import { HStack, Icon, Link, Text } from '@chakra-ui/react'

export type BackLinkProps = {
  to?: string
  label?: string
}

export const BackLink = ({ to = '../', label }: BackLinkProps) => {
  return (
    <Link as={NavLink} to={to} variant="standalone" p={0}>
      <HStack spacing="0.5rem" align="center">
        <Icon as={BiLeftArrowAlt} fontSize="1.25rem" />
        {label && <Text>{label}</Text>}
      </HStack>
    </Link>
  )
}
