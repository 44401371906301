import { useCallback } from 'react'
import { BiTrash } from 'react-icons/bi'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react'
import { Button, ModalCloseButton } from '@opengovsg/design-system-react'

import { GetDocumentDto, GetSubmissionDto } from '~shared/dtos'

import { useAdminUser } from '~/features/auth/hooks'

import { useDeleteDocumentsMutation } from '../dashboard/hooks/submissions.hooks'

type DeleteModalProps = Pick<UseDisclosureReturn, 'onClose' | 'isOpen'> & {
  submission: GetSubmissionDto
  documents: GetDocumentDto[]
}

export const DeleteModal = ({
  onClose: onCloseDisclosure,
  isOpen,
  submission,
}: DeleteModalProps) => {
  const { adminUser } = useAdminUser()

  const { mutateAsync, isLoading } = useDeleteDocumentsMutation(
    {
      userId: adminUser?.id,
      submissionId: submission.id,
    },
    {
      onSuccess: () => {
        onClose()
      },
      onError: () => {
        onClose()
      },
    },
  )

  const onClose = useCallback(() => {
    onCloseDisclosure()
  }, [onCloseDisclosure])

  const handleClick = () => {
    mutateAsync()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Are you sure you would like to delete files in{' '}
          {submission.campaignName}?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} align="stretch">
            <Text>
              This action will permanently delete all files inside your
              submission. Once deleted, they cannot be recovered. Would you like
              to proceed?
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="flex-start">
          <Button
            type="submit"
            onClick={handleClick}
            isLoading={isLoading}
            leftIcon={<BiTrash size={24} />}
            color="base.content.inverse"
            bgColor="interaction.main.default"
            _hover={{
              bgColor: 'interaction.main.hover',
              _disabled: { bgColor: 'interaction.support.disabled' },
            }}
            _disabled={{
              cursor: 'not-allowed',
              border: 'none',
              color: 'interaction.support.disabled-content',
              bgColor: 'interaction.support.disabled',
            }}
          >
            Delete files
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
