import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'

dayjs.extend(isToday)

export const fmtDateMonthInLetters = (isoString: string | null): string => {
  if (!isoString) return ''

  const date = new Date(isoString)
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(date)
}

export const fmtDate = (
  date: Date,
  fmtString = 'DD/MM/YYYY',
  today = false, // Print "Today if Today"
): string => {
  const d = dayjs(date)
  return d.isToday() && today ? 'Today' : dayjs(date).format(fmtString)
}

export const fmtDateTime = (date: Date): string => {
  return dayjs(date).format('DD/MM/YYYY HH:mm')
}

export const fmtBytes = (numBytes: number): string => {
  if (numBytes == 0) return '0 Bytes'
  const k = 1024
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(numBytes) / Math.log(k))
  const num = numBytes / Math.pow(k, i)
  const dp = Math.max(0, 2 - Math.floor(Math.log10(num)))
  return `${num.toFixed(dp)} ${units[i]}`
}

// Capitalizes every start of word, including punctuation and spaces
export const frontCase = (str: string): string => {
  return str.toLowerCase().replace(/(^\w|\W\w)/g, (m) => m.toUpperCase())
}

export const fmtCurrency = (value: number, round = false): string => {
  const formatter = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    ...(round
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      : {}),
  })
  return formatter.format(value)
}

export const fmtPercentage = (value: number, round = false): string => {
  const percentage = value * 100
  return round ? `${Math.round(percentage)}%` : `${percentage.toFixed(2)}%`
}
