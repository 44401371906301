import { BiFile } from 'react-icons/bi'
import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Icon,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import dayjs from 'dayjs'

import { GetDocumentDto } from '~shared/dtos'

import { BackLink } from '~/components/BackLink'
import { DownloadButton } from '~/features/download'
import { fmtBytes } from '~/utils/humanReadable'

import { useSubmission, useSubmissionId } from './hooks/submissions.hooks'
import { EditSubmissionInstructionsModal } from './Submissions/EditSubmissionInstructionsModal'
import { ShareSubmissionModal } from './Submissions/ShareSubmissionModal'
import { SubmissionActionButtons } from './Submissions/SubmissionActionButtons'

export const SubmissionPage = () => {
  const { submissionId } = useSubmissionId()
  const { submission, isSubmissionLoading } = useSubmission(submissionId)
  const editDisclosure = useDisclosure()
  const shareDisclosure = useDisclosure()

  if (!submission || isSubmissionLoading) return <Spinner />

  const documentSize = submission.documents.reduce(
    (total, doc) => total + doc.sizeInBytes,
    0,
  )

  return (
    <>
      <VStack align="stretch" spacing={10} p={6} w="full">
        <Flex align="center">
          <HStack align="center" spacing={6}>
            <BackLink />
            <Text ml={5} textStyle="h4">
              {submission.internalId}
            </Text>
          </HStack>
          <Spacer />
          <SubmissionActionButtons
            variant="outline"
            colorScheme="black"
            submission={submission}
            onShare={shareDisclosure.onOpen}
            onEdit={editDisclosure.onOpen}
            documentSize={documentSize}
          />
        </Flex>
        <VStack align="stretch" divider={<Divider />} spacing={0}>
          <Header />
          {submission.documents.map((document) => (
            <DocumentRow
              key={document.id}
              campaignId={submission.campaignId}
              campaignName={submission.campaignName}
              campaignPublicKey={submission.campaignPublicKey}
              document={document}
            />
          ))}
        </VStack>
      </VStack>
      <EditSubmissionInstructionsModal
        {...editDisclosure}
        submission={submission}
      />
      <ShareSubmissionModal {...shareDisclosure} submission={submission} />
    </>
  )
}

const DocumentRow = ({
  document,
  campaignId,
  campaignName,
  campaignPublicKey,
}: {
  document: GetDocumentDto
  campaignId: number
  campaignName: string
  campaignPublicKey: string
}) => {
  return (
    <Flex
      w="full"
      py={4}
      align="center"
      bgColor="whiteAlpha.500"
      _hover={{ bgColor: 'green.100' }}
      px={2}
    >
      <HStack flex={3} px={2}>
        <Icon as={BiFile} fontSize="1.25rem" />
        <Text>{document.name}</Text>
      </HStack>
      <Box flex={2} px={2}>
        {fmtBytes(document.sizeInBytes)}
      </Box>
      <Box flex={2} px={2}>
        {dayjs(document.createdAt).format('DD/MM/YYYY hh:mm a')}
      </Box>
      <Box flex={2} px={2}>
        <DownloadButton
          campaignId={campaignId}
          campaignName={campaignName}
          campaignPublicKey={campaignPublicKey}
          documents={[document]}
          isDisabled={document.isMalicious === null} // disable download button, if virus scan not complete
          variant="clear"
        />
      </Box>
    </Flex>
  )
}

const Header = () => {
  return (
    <Flex w="full" py={2} align="center" fontWeight="bold" px={2}>
      <Box flex={3} px={2}>
        Name
      </Box>
      <Box flex={2} px={2}>
        File Size
      </Box>
      <Box flex={2} px={2}>
        Uploaded at
      </Box>
      <Box flex={2} px={2}>
        Actions
      </Box>
    </Flex>
  )
}
