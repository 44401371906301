import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  GridProps,
  Image,
  Text,
} from '@chakra-ui/react'

import { AppFooter } from '~/app/AppFooter'
import FileHandoverSvg from '~/assets/FileHandover.svg'
import LogoSvg from '~/assets/Logo.svg'

import { LoginForm, LoginFormInputs } from './components/LoginForm'
import { OtpForm, OtpFormInputs } from './components/OtpForm'
import { useAdminAuth } from './AdminAuthContext'
import { useAdminSendLoginOtp, useAdminVerifyLoginOtp } from './hooks'

const AppGrid = (props: GridProps) => (
  <Grid
    px={{ base: '1.5rem', md: '1.75rem', lg: '2rem' }}
    columnGap={{ base: '0.5rem', lg: '1rem' }}
    templateColumns={{ base: 'repeat(4, 1fr)', md: 'repeat(12, 1fr)' }}
    {...props}
  />
)

// Component for the split blue/white background.
const BackgroundBox: FC<PropsWithChildren> = ({ children }) => (
  <Flex
    flex={1}
    overflow={{ lg: 'auto' }}
    flexDir="column"
    h="inherit"
    bgGradient={{
      md: 'linear(to-b, brand.primary.100 20.5rem, white 0)',
      lg: 'linear(to-r, brand.primary.100 calc(41.6667% - 4px), white 0)',
    }}
  >
    {children}
  </Flex>
)

// Component that controls the various grid areas according to responsive breakpoints.
const BaseGridLayout = (props: GridProps) => (
  <AppGrid templateRows={{ md: 'auto 1fr auto', lg: '1fr auto' }} {...props} />
)

// Grid area styling for the login form.
const LoginGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    gridColumn={{ base: '1 / 5', md: '2 / 12', lg: '7 / 12' }}
    py="4rem"
    display="flex"
    alignItems={{ base: 'initial', lg: 'center' }}
    justifyContent="center"
  >
    {children}
  </GridItem>
)

// Grid area styling for the footer.
const FooterGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    alignSelf="end"
    gridColumn={{ base: '1 / 5', md: '2 / 12' }}
    pb={{ base: 0, lg: '2.5rem' }}
    bg={{ base: 'base.canvas.brandLight', lg: 'transparent' }}
  >
    {children}
  </GridItem>
)

// Grid area styling for the left sidebar that only displays on tablet and desktop breakpoints.
const NonMobileSidebarGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    display={{ base: 'none', md: 'flex' }}
    gridColumn={{ md: '1 / 13', lg: '1 / 6' }}
    h={{ md: '20.5rem', lg: 'auto' }}
    pt={{ base: '1.5rem', md: '2.5rem', lg: '3rem' }}
    pb={{ lg: '3rem' }}
    flexDir="column"
    alignItems={{ base: 'center', lg: 'center' }}
    justifyContent="center"
  >
    {children}
  </GridItem>
)

export const LoginPage = (): JSX.Element => {
  const { verifyLoginOtp } = useAdminVerifyLoginOtp()
  const { sendLoginOtp } = useAdminSendLoginOtp()
  const { adminUser } = useAdminAuth()
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>()

  const handleSendOtp = async ({ email }: LoginFormInputs) => {
    const trimmedAndLoweredCaseEmail = email.trim().toLowerCase()
    await sendLoginOtp({ email: trimmedAndLoweredCaseEmail })
    return setEmail(trimmedAndLoweredCaseEmail)
  }

  // If user is already logged in, redirect to dashboard.
  useEffect(() => {
    if (adminUser) navigate('/admin/dashboard')
  }, [navigate, adminUser])

  const handleVerifyOtp = async ({ token }: OtpFormInputs) => {
    // Should not happen, since OtpForm component is only shown when there is
    // already an email state set.
    if (!email) {
      throw new Error('Something went wrong')
    }
    return verifyLoginOtp(
      { token, email },
      {
        onSuccess: () => navigate('/admin/collections'),
      },
    )
  }

  const handleResendOtp = async () => {
    // Should not happen, since OtpForm component is only shown when there is
    // already an email state set.
    if (!email) {
      throw new Error('Something went wrong')
    }
    await sendLoginOtp({ email })
  }

  return (
    <BackgroundBox>
      <BaseGridLayout flex={1}>
        <NonMobileSidebarGridArea>
          <Text textStyle="h1" color="brand.primary.500">
            Easy Documents Submission
          </Text>
          <Image maxW="100%" src={FileHandoverSvg} />
        </NonMobileSidebarGridArea>
        <LoginGridArea>
          <Box minH={{ base: 'auto', lg: '17.25rem' }} w="100%">
            <Image src={LogoSvg} maxW="10rem" mb="2.5rem" />
            {!email ? (
              <LoginForm onSubmit={handleSendOtp} />
            ) : (
              <OtpForm
                email={email}
                onSubmit={async (values) => {
                  await handleVerifyOtp(values)
                }}
                onResendOtp={handleResendOtp}
              />
            )}
          </Box>
        </LoginGridArea>
      </BaseGridLayout>
      <BaseGridLayout
        bg={{ base: 'base.canvas.brandLight', lg: 'transparent' }}
      >
        <FooterGridArea>
          <AppFooter
            containerProps={{
              px: 0,
              bg: 'transparent',
            }}
            variant={{ lg: 'compact' }}
            colorMode="light"
          />
        </FooterGridArea>
      </BaseGridLayout>
    </BackgroundBox>
  )
}
