import { ComponentStyleConfig } from '@chakra-ui/react'

export const Tabs: ComponentStyleConfig = {
  variants: {
    vertical: {
      tablist: {
        mr: 2,
      },
      tab: {
        w: 'full',
        justifyContent: 'flex-start',
        color: 'neutral.700',
        textStyle: 'subhead-1',
        px: 6,
        py: 4,
        borderLeft: '2px solid',
        borderColor: 'grey.100',
        _hover: {
          bgColor: 'primary.50',
        },
        _selected: {
          color: 'primary.500',
          borderColor: 'primary.500',
        },
      },
    },
  },
}
