import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Text, useDisclosure, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { GetSubmissionDto } from '~shared/dtos'

import { EditSubmissionInstructionsModal } from './EditSubmissionInstructionsModal'
import { SubmissionActionButtons } from './SubmissionActionButtons'

export const Submission = ({
  submission,
  onShare,
}: {
  submission: GetSubmissionDto
  onShare: (s: GetSubmissionDto) => void
}) => {
  const { name, documents, internalId, id } = submission
  const navigate = useNavigate()
  const disclosure = useDisclosure()

  const lastUpdatedAt = useMemo(() => {
    const documentUpdatedAt = documents
      .map((d) => dayjs(d.updatedAt))
      .sort((a, b) => b.diff(a))

    return documentUpdatedAt[0]?.format('DD/MM/YYYY hh:mm a') ?? '--'
  }, [documents])
  // Disable the submission if its files have already expired
  const isSubmissionDisabled =
    !!submission.filesExpireAt &&
    dayjs(submission.filesExpireAt) < dayjs().startOf('day')

  const documentSize = submission.documents.reduce(
    (total, doc) => total + doc.sizeInBytes,
    0,
  )

  return (
    <>
      <Flex
        align="center"
        minH="40px"
        bgColor={isSubmissionDisabled ? 'grey.100' : undefined}
        _hover={
          isSubmissionDisabled
            ? undefined
            : {
                bgColor: 'interaction.main-subtle.default',
                cursor: 'pointer',
              }
        }
        onClick={
          isSubmissionDisabled
            ? undefined
            : () =>
                navigate(
                  `/admin/collections/${submission.campaignId}/submission/${id}`,
                )
        }
        py={2}
        px={4}
      >
        <VStack align="start" spacing={0} flex={3}>
          <Text>{internalId}</Text>
          {name && (
            <Text textStyle="body-2" color="grey.500">
              {name}
            </Text>
          )}
        </VStack>
        <Text flex={2}>{documents.length}</Text>
        <Text flex={2}>{lastUpdatedAt}</Text>
        <SubmissionActionButtons
          submission={submission}
          onShare={onShare}
          onEdit={disclosure.onOpen}
          isDisabled={isSubmissionDisabled}
          flex={2}
          variant="clear"
          colorScheme="secondary"
          documentSize={documentSize}
        />
      </Flex>
      <EditSubmissionInstructionsModal
        {...disclosure}
        submission={submission}
      />
    </>
  )
}

export const SubmissionHeader = () => {
  return (
    <Flex fontWeight="bold" w="full" p={4}>
      <Text flex={3}>Name</Text>
      <Text flex={2}>{`No. of files`}</Text>
      <Text flex={2}>Last Updated</Text>
      <Text flex={2}>Actions</Text>
    </Flex>
  )
}
