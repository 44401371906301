import wretch from 'wretch'

import {
  FORBIDDEN_DOWNLOAD_IP_ERROR_MESSAGE,
  FORBIDDEN_UPLOAD_IP_ERROR_MESSAGE,
} from '~shared/constants/errors'

import { UNAUTHORIZED_EVENT } from '~/constants/events'
import { ResponseErrorJson } from '~/types/error'

/**
 * Default API client pointing to backend.
 * Automatically catches 403 errors and invalidates authentication state.
 */
export const api = wretch('/api')
  .catcher(403, (err) => {
    const json = err.json as ResponseErrorJson
    if (
      ![
        FORBIDDEN_UPLOAD_IP_ERROR_MESSAGE,
        FORBIDDEN_DOWNLOAD_IP_ERROR_MESSAGE,
      ].includes(json.message)
    ) {
      window.dispatchEvent(new Event(UNAUTHORIZED_EVENT))
    }
    throw err
  })
  .errorType('json')
