export const SUBMISSION_ID_REGEX = /^[a-zA-Z0-9\-_()!. ]+$/
export const SUBMISSION_ID_REGEX_ERROR =
  'Identifier should only consist of alphanumerics and ()!.-_ symbols.'
export const SUBMISSION_MAX_ID_LEN = 100
export const SUBMISSION_MAX_ID_LEN_ERROR = `Identifier too long. Maximum of ${SUBMISSION_MAX_ID_LEN} characters.`
export const SUBMISSION_NAME_REGEX = /^[a-zA-Z0-9]+(\s+[a-zA-Z0-9]+)*$/
export const SUBMISSION_NAME_REGEX_ERROR =
  'Name should only consist of alphanumerics'
export const SUBMISSION_MAX_NAME_LEN = 100
export const SUBMISSION_MAX_NAME_LEN_ERROR = `Submission name too long. Maximum of ${SUBMISSION_MAX_NAME_LEN} characters.`
export const FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS = 90
