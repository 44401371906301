import { useQuery } from '@tanstack/react-query'

import { GetAnnouncementBannerConfigDto } from '~shared/dtos'

import { adminQueryKeys } from '~/constants/query-keys'
import { api } from '~/lib/api'

export const useGetAnnouncementBannerConfig = () => {
  const { data, isLoading } = useQuery(adminQueryKeys.announcementBanner, () =>
    api
      .url(`/config/announcement-banner`)
      .get()
      .json<GetAnnouncementBannerConfigDto>(),
  )
  return { data, isLoading }
}
