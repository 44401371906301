import { v4 as uuidv4 } from 'uuid'

const getFileNameAndExtension = (name: string) => {
  const splitFilename = name.split('.')
  // filename always comes with ext
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const extension = splitFilename.pop()!
  return {
    fileNameWoExt: name.slice(0, name.lastIndexOf(extension)),
    extension,
  }
}

export const generateS3ObjectKey = (filename: string): string => {
  const { fileNameWoExt, extension } = getFileNameAndExtension(filename)
  const objectKeySuffix = uuidv4()

  return extension
    ? `${fileNameWoExt}_${objectKeySuffix}.${extension}`
    : `${fileNameWoExt}_${objectKeySuffix}`
}
