// adapted from https://github.com/opengovsg/calsg/blob/develop/frontend/src/features/common/landing/LandingPage.tsx
import { useEffect } from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'

import { AppFooter } from '~/app/AppFooter'
import FileHandoverSvg from '~/assets/FileHandover.svg'
import EndToEndEncryptionSvg from '~/assets/landing/EndToEndEncryption.svg'
import OgpSuiteSvg from '~/assets/landing/OgpSuite.svg'
import PersonalizedLinksSvg from '~/assets/landing/PersonalizedLinks.svg'
import TrackSubmissionsSvg from '~/assets/landing/TrackSubmissions.svg'
import { routes } from '~/constants/routes'
import { useIsDesktop } from '~/hooks/useIsDesktop'

import { useAdminAuth } from '../auth'

import { FeatureGridItem } from './components/FeatureGridItem'
import { LandingSection } from './components/LandingSection'
import { PublicHeader } from './components/PublicHeader'

export const LandingPage = (): JSX.Element => {
  const isDesktop = useIsDesktop()
  const { adminUser } = useAdminAuth()
  const navigate = useNavigate()

  // If user is already logged in, redirect to dashboard.
  useEffect(() => {
    if (adminUser) navigate(routes.collections)
  }, [navigate, adminUser])

  return (
    <Flex flexDir="column">
      <PublicHeader />
      <LandingSection>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          align="left"
          spacing={{ base: '1.5rem', md: '3.125rem' }}
        >
          <Flex flexDir="column" flex={1}>
            <Text
              textStyle={{
                base: 'responsive-display.heavy',
                md: 'responsive-display.heavy-480',
                lg: 'responsive-display.heavy-600',
              }}
              pb="2rem"
            >
              Collect files from citizens securely in minutes
            </Text>
            <Text>
              {`Secure & personalised links for file collection, at zero cost and no onboarding.`}
            </Text>
            <RouterLink to={routes.admin.login}>
              <Button
                w={isDesktop ? 'unset' : 'full'}
                mt="2.5rem"
                rightIcon={<BiRightArrowAlt />}
              >
                Start collecting files now
              </Button>
            </RouterLink>
          </Flex>
          <Image src={FileHandoverSvg} />
        </Stack>
      </LandingSection>
      <LandingSection bg="grey.50">
        <Text
          textStyle={{
            base: 'responsive-heading.heavy',
            md: 'responsive-heading.heavy-480',
            lg: 'responsive-heading.heavy-600',
          }}
        >
          Why use Highway?
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacingX="2.5rem"
          spacingY="4rem"
          mt="4rem"
        >
          <FeatureGridItem
            image={TrackSubmissionsSvg}
            title="Personalised submission links"
            description="Create and share links with personalised instructions for file submission with recipients."
          />
          <FeatureGridItem
            image={EndToEndEncryptionSvg}
            title="End-to-end encryption"
            description="Collect files securely on the platform that can only be accessed with a unique secret key."
          />
          <FeatureGridItem
            image={PersonalizedLinksSvg}
            title="Track submission of files"
            description="Monitor when your recipients have uploaded the requested files through email updates."
          />
        </SimpleGrid>
      </LandingSection>
      <LandingSection>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          align="left"
          spacing={{ base: '1.5rem', md: '3.125rem' }}
        >
          <Flex flexDir="column" flex={1}>
            <Text
              textStyle={{
                base: 'responsive-heading.heavy',
                md: 'responsive-heading.heavy-480',
                lg: 'responsive-heading.heavy-600',
              }}
              pb="2rem"
            >
              All the government tools you need to manage your workflow
            </Text>
            <Text>
              <Text>
                <Text as="span">{`Highway is part of the `}</Text>
                <Text fontWeight="bold" as="span">
                  Open Government Products Suite
                </Text>
                <Text as="span">{`, and as a public officer you can mix and match from our set of productivity and collaboration tools.`}</Text>
              </Text>
            </Text>
            <Link href={'https://www.open.gov.sg/products/overview'} isExternal>
              <Button
                variant="link"
                mt="2.5rem"
                rightIcon={<BiRightArrowAlt />}
              >
                Full list of OGP products
              </Button>
            </Link>
          </Flex>
          <Image src={OgpSuiteSvg} />
        </Stack>
      </LandingSection>
      <AppFooter />
    </Flex>
  )
}
