import { createMessage, decrypt, encrypt, readMessage } from 'openpgp'
import { box, randomBytes } from 'tweetnacl'
import { decodeBase64, encodeBase64 } from 'tweetnacl-util'

export class EncryptionService {
  /*
    Generates a key pair from NaCl, returning
    in the order [publicKey,secretKey]
  */
  generateAsymmetricKeyPair = () => {
    const kp = box.keyPair()
    return {
      publicKey: encodeBase64(kp.publicKey),
      privateKey: encodeBase64(kp.secretKey),
    }
  }

  verifyAsymmetricKey = (publicKey: string, privateKey: string) => {
    try {
      const decodedPrivateKey = decodeBase64(privateKey)
      const { publicKey: decodedPublicKey } =
        box.keyPair.fromSecretKey(decodedPrivateKey)
      const encodedPublicKey = encodeBase64(decodedPublicKey)
      return publicKey === encodedPublicKey
    } catch (e) {
      return false
    }
  }

  /*
    Encrypts a symmetric key with the provided public key.
    Returns a string in the form of
    '<submissionPublicKey>;<nonce>:<encryptedMessage>'
  */
  encryptKeyAsymmetrically = (
    publicKey: string,
    symmetricKey: string,
  ): string => {
    const documentKeypair = box.keyPair()
    const nonce = randomBytes(box.nonceLength)
    const encrypted = encodeBase64(
      box(
        decodeBase64(symmetricKey),
        nonce,
        decodeBase64(publicKey),
        documentKeypair.secretKey,
      ),
    )
    return `${encodeBase64(documentKeypair.publicKey)};${encodeBase64(
      nonce,
    )}:${encrypted}`
  }

  /*
    Decrypts a encrypted symmetric key with submission public key,
    given private key and nonce. 
    
    Returns null if decryption fails, else return symmetric key
    */
  decryptKeyAsymmetrically = (
    privateKey: string,
    encryptedSymmetricKey: string,
  ): string | null => {
    try {
      const [documentPublicKey, nonceEncrypted] =
        encryptedSymmetricKey.split(';')
      const [nonce, encrypted] = nonceEncrypted.split(':').map(decodeBase64)
      const decryptedSymmetricKey = box.open(
        encrypted,
        nonce,
        decodeBase64(documentPublicKey),
        decodeBase64(privateKey),
      )
      if (!decryptedSymmetricKey) {
        return null
      }
      return encodeBase64(decryptedSymmetricKey)
    } catch (err) {
      return null
    }
  }

  generateSymmetricKey = (): string => {
    return encodeBase64(randomBytes(32))
  }

  /*
    Uses symmetric key to symmetrically encrypt a Uint8Array with OpenPGP
    Returns an encrypted Uint8Array
  */
  encryptUint8ArraySymmetrically = async (
    symmetricKey: string,
    fileUint8Array: Uint8Array,
  ) => {
    const message = await createMessage({
      binary: fileUint8Array,
    })

    const encrypted = await encrypt({
      message,
      passwords: [symmetricKey],
      format: 'binary',
    })

    return new Uint8Array(encrypted.buffer)
  }

  convertUint8ArrayToBlob = (array: Uint8Array) => {
    return new Blob([array], { type: 'application/octet-stream' })
  }

  convertBlobToUint8Array = async (fileData: Blob) => {
    return new Uint8Array(await fileData.arrayBuffer())
  }

  /*
    Uses symmetric key to decrypt an encrypted Uint8Array with OpenPGP
    Returns a decrypted Uint8array
  */
  decryptUint8ArraySymmetrically = async (
    symmetricKey: string,
    encryptedFileUint8Array: Uint8Array,
  ) => {
    const encryptedMessage = await readMessage({
      binaryMessage: encryptedFileUint8Array,
    })
    const { data: decrypted } = await decrypt({
      message: encryptedMessage,
      passwords: [symmetricKey],
      format: 'binary',
    })

    return decrypted
  }

  encryptFileSymmetrically = async (
    symmetricKey: string,
    fileData: Blob,
  ): Promise<Blob> => {
    const fileUint8Array = await this.convertBlobToUint8Array(fileData)
    const encryptedFileUint8Array = await this.encryptUint8ArraySymmetrically(
      symmetricKey,
      fileUint8Array,
    )
    const encryptedFile = this.convertUint8ArrayToBlob(encryptedFileUint8Array)
    return encryptedFile
  }

  decryptFileSymmetrically = async (
    symmtricKey: string,
    fileData: Blob,
  ): Promise<Blob> => {
    const fileUint8Array = await this.convertBlobToUint8Array(fileData)
    const decryptedFileUint8Array = await this.decryptUint8ArraySymmetrically(
      symmtricKey,
      fileUint8Array,
    )
    const decryptedFile = this.convertUint8ArrayToBlob(decryptedFileUint8Array)
    return decryptedFile
  }
}

export const encryptionService = new EncryptionService()
