import { Outlet } from 'react-router-dom'
import { Box, VStack } from '@chakra-ui/react'

import { AppFooter } from '~/app/AppFooter'
import { CampaignSidebar } from '~/features/dashboard/CampaignSidebar/CampaignSidebar'
import { Navbar } from '~/features/navbar'

export const AdminLayout = () => {
  return (
    <VStack minWidth="100%" align="stretch" spacing={0}>
      <Navbar />
      <Box px="admin-app-px" minH="admin-content-min-height">
        <CampaignSidebar>
          <Outlet />
        </CampaignSidebar>
      </Box>
      <AppFooter containerProps={{ px: '3rem' }} />
    </VStack>
  )
}
