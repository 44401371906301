import { BiTrash } from 'react-icons/bi'
import { useDisclosure } from '@chakra-ui/react'
import { IconButton, IconButtonProps } from '@opengovsg/design-system-react'

import { GetSubmissionDto } from '~shared/dtos'

import { DeleteModal } from './DeleteModal'

export interface DeleteButtonProps {
  submission: GetSubmissionDto
  variant?: IconButtonProps['variant']
  isDisabled?: boolean
}

export const DeleteButton = ({
  submission,
  variant,
  isDisabled = false,
}: DeleteButtonProps): JSX.Element => {
  const { documents } = submission
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <IconButton
        aria-label={`Delete ${
          documents.length === 1 ? documents[0].name : 'all'
        }`}
        icon={<BiTrash />}
        size="md"
        {...(variant && { variant })}
        isDisabled={isDisabled || documents.length === 0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          onOpen()
        }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        submission={submission}
        documents={documents}
      />
    </>
  )
}
