import { useState } from 'react'
import { BiCheckCircle, BiDownload, BiError } from 'react-icons/bi'
import {
  Box,
  HStack,
  Progress,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {
  IconButton,
  IconButtonProps,
  Spinner,
} from '@opengovsg/design-system-react'

import { GetDocumentDto } from '~shared/dtos'

import { useAdminUser } from '~/features/auth/hooks'
import { useCampaignPrivateKey } from '~/features/dashboard'
import { DownloadModal } from '~/features/download'
import { useToast } from '~/hooks/useToast'
import { decryptAndDownload, DownloadStatus } from '~/utils/download'

export interface DownloadButtonProps {
  campaignId: number
  campaignName: string
  campaignPublicKey: string
  documents: GetDocumentDto[]
  variant?: IconButtonProps['variant']
  isDisabled?: boolean
}

export const DownloadButton = ({
  campaignId,
  campaignName,
  campaignPublicKey,
  documents,
  variant,
  isDisabled = false,
}: DownloadButtonProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { adminUser } = useAdminUser()
  const campaignPrivateKey = useCampaignPrivateKey(campaignId)
  const toast = useToast()
  const [progressInfo, setProgressInfo] = useState({
    progress: -1,
    info: DownloadStatus.INITIALIZING,
  })

  const getProgressComponent = () => {
    switch (progressInfo.info) {
      case DownloadStatus.INITIALIZING:
        return (
          <>
            <Spinner fontSize={'1.5rem'} color="primary.400" />
            <Text textStyle="caption-1" color="secondary.500">
              Initializing download...
            </Text>
          </>
        )
      case DownloadStatus.DOWNLOADING:
        return (
          <>
            <Progress value={progressInfo.progress} w="100%" size="sm" />
            <Text textStyle="caption-1" color="secondary.500">
              {progressInfo.progress}%
            </Text>
          </>
        )
      case DownloadStatus.DECRYPTING:
        return (
          <>
            <Spinner fontSize={'1.5rem'} color="primary.400" />
            <Text textStyle="caption-1" color="secondary.500">
              Decrypting file...
            </Text>
          </>
        )
      case DownloadStatus.FAILED:
        return (
          <>
            <Box fontSize={'1.5rem'} color="red.400">
              <BiError />
            </Box>
            <Text textStyle="caption-1" color="secondary.500">
              Download failed, refresh page to try again
            </Text>
          </>
        )
      case DownloadStatus.COMPLETED:
        return (
          <>
            <Box fontSize={'1.5rem'} color="success.400">
              <BiCheckCircle />
            </Box>
            <Text textStyle="caption-1" color="secondary.500">
              Download complete
            </Text>
          </>
        )
    }
  }

  const handleClick = async () => {
    if (campaignPrivateKey === null) {
      onOpen()
      return
    }
    try {
      await decryptAndDownload(
        documents,
        campaignPrivateKey,
        setProgressInfo,
        adminUser?.id,
      )
    } catch (err) {
      toast({
        status: 'error',
        description:
          err instanceof Error ? err.message : 'Something went wrong.',
      })
    }
  }

  return (
    <>
      {progressInfo.progress < 0 || progressInfo.progress > 100 ? (
        <Tooltip
          label={
            isDisabled
              ? 'You can download the document once the file has been scanned for viruses'
              : `Download ${documents.length === 1 ? documents[0].name : 'all'}`
          }
          placement="top"
        >
          <IconButton
            aria-label={`Download ${
              documents.length === 1 ? documents[0].name : 'all'
            }`}
            icon={<BiDownload />}
            size="md"
            {...(variant && { variant })}
            isDisabled={isDisabled || documents.length === 0}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              handleClick()
            }}
          />
        </Tooltip>
      ) : (
        <HStack>{getProgressComponent()}</HStack>
      )}
      <DownloadModal
        isOpen={isOpen}
        onClose={onClose}
        campaignId={campaignId}
        campaignName={campaignName}
        campaignPublicKey={campaignPublicKey}
        documents={documents}
        setProgress={setProgressInfo}
      />
    </>
  )
}
