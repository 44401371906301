import { useCallback } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react'
import {
  DatePicker,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  ModalCloseButton,
} from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS } from '~shared/constants'
import { GetSubmissionDto, UpdateSubmissionReq } from '~shared/dtos'
import { isSubmissionLinkExpiryDateValid } from '~shared/utils/submissions'

import { useAdminAuth } from '~/features/auth'
import { RichTextEditor } from '~/features/richtext'
import { useToast } from '~/hooks/useToast'

import { useUpdateSubmissionMutation } from '../hooks/submissions.hooks'

type EditSubmissionInstructionsModalProp = UseDisclosureReturn & {
  submission: GetSubmissionDto
}

export const EditSubmissionInstructionsModal = ({
  onClose: onCloseDisclosure,
  isOpen,
  submission,
}: EditSubmissionInstructionsModalProp) => {
  const toast = useToast()
  const { adminUser } = useAdminAuth()

  const { mutateAsync, isLoading } = useUpdateSubmissionMutation({
    userId: adminUser?.id,
    submissionId: submission.id,
  })

  const formMethods = useForm<
    Pick<UpdateSubmissionReq, 'expiresAt' | 'instructions'>
  >({
    mode: 'onChange',
    defaultValues: {
      expiresAt: dayjs(submission.expiresAt).toDate(),
      instructions: submission.instructions,
    },
  })

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = formMethods

  const onClose = useCallback(() => {
    onCloseDisclosure()
    reset()
  }, [onCloseDisclosure, reset])

  const rules = {
    required: 'This field is required',
  }

  // Do not allow link expiry date to be updated once the submission link expiry has passed
  const isUpdatingLinkExpiryDateAllowed =
    dayjs(submission.expiresAt).toDate() < dayjs().startOf('day').toDate()

  const onSubmit = handleSubmit((formValues) => {
    const { expiresAt, ...formValuesExceptExpiresAt } = formValues
    const updateSubmissionReq: UpdateSubmissionReq =
      isUpdatingLinkExpiryDateAllowed ? formValuesExceptExpiresAt : formValues

    return mutateAsync(updateSubmissionReq, {
      onSuccess: () => {
        reset({
          expiresAt: formValues.expiresAt,
          instructions: formValues.instructions,
        })
        onClose()
        toast({
          status: 'success',
          description: 'Submission link updated',
        })
      },
      onError: () => {
        toast({
          status: 'error',
          description:
            'There was an error creating the link. Please try again later.',
        })
      },
    })
  })

  return (
    <FormProvider {...formMethods}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Submission Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={10} align="stretch">
              <FormControl isInvalid={!!errors.expiresAt}>
                <FormLabel isRequired>{'Link expiry date'}</FormLabel>
                <FormHelperText>
                  The link will be open for file submissions till this date
                  (inclusive). Once the submission link has expired, you will
                  not be able to change this date.
                </FormHelperText>
                <HStack mb={2} spacing="0.25rem">
                  <WarningTwoIcon color="utility.feedback.critical" />
                  <FormHelperText textColor="utility.feedback.critical">
                    All files collected on this link will be deleted after{' '}
                    {FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS} days of this date.
                  </FormHelperText>
                </HStack>
                <Controller
                  name="expiresAt"
                  rules={{
                    required: 'Please enter a date',
                    validate: {
                      pattern: (date: Date) => {
                        if (
                          !isUpdatingLinkExpiryDateAllowed &&
                          !isSubmissionLinkExpiryDateValid(date)
                        )
                          return 'Date cannot be in the past'
                      },
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      onChange={onChange}
                      value={value}
                      name={'expiry-datepicker'}
                      disabled={isUpdatingLinkExpiryDateAllowed}
                      isDateUnavailable={(date) =>
                        !isSubmissionLinkExpiryDateValid(date)
                      }
                    />
                  )}
                />
                <FormErrorMessage>{errors.expiresAt?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.instructions}>
                <FormLabel isRequired>{'Instructions'}</FormLabel>
                <FormHelperText mb={2}>
                  Include your requirements or any other information you would
                  like the receiver to know here.
                </FormHelperText>
                <Controller
                  name="instructions"
                  rules={rules}
                  render={({ field: { value } }) => (
                    <RichTextEditor
                      content={value as string}
                      setContent={(c) =>
                        setValue('instructions', c, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.instructions?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={8}
              variant="clear"
              colorScheme="secondary"
              isDisabled={!isDirty}
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              isLoading={isLoading}
              isDisabled={
                !!errors.instructions || !!errors.expiresAt || !isDirty
              }
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  )
}
