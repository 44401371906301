import { useState } from 'react'
import { VStack } from '@chakra-ui/react'
import { Content, EditorContent, useEditor } from '@tiptap/react'

import { CharacterCountView } from './CharacterCountView'
import { RichTextMenu } from './RichTextMenu'
import { styles } from './styles'
import { extensions } from './utils'

export const RichTextEditor = ({
  content,
  setContent,
  isReadOnly = false,
  onChange,
}: {
  content: Content
  setContent?: (content: string) => void
  isReadOnly?: boolean
  onChange?: VoidFunction
}) => {
  const editor = useEditor({
    extensions,
    onUpdate: ({ editor }) => {
      setContent?.(editor.getHTML())
      onChange?.()
    },
    content,
    onFocus: () => {
      setIsFocused(true)
    },
    onBlur: () => {
      setIsFocused(false)
    },
  })

  if (isReadOnly) {
    editor?.setEditable(false)
  }

  const [isFocused, setIsFocused] = useState(false)

  return (
    <VStack
      width="full"
      alignItems="left"
      textAlign="start"
      sx={styles({
        isFocused,
        isReadOnly,
      })}
      spacing={0}
    >
      {!isReadOnly && <RichTextMenu editor={editor} />}
      <EditorContent editor={editor} />
      {!!editor && !isReadOnly && <CharacterCountView editor={editor} />}
    </VStack>
  )
}
