import { SystemStyleObject } from '@chakra-ui/react'

export const TITLE_STYLE = {
  lineHeight: 1.5,
  fontSize: '1.9rem',
  fontWeight: 600,
  mb: '0.5rem',
}

export const SUBTITLE_STYLE = {
  lineHeight: 1.2,
  fontSize: '1.6rem',
  fontWeight: 500,
  mb: '0.2rem',
}

export const HEADER_STYLE = {
  lineHeight: 1,
  fontSize: '1.3rem',
  fontWeight: 400,
  mb: '0.2rem',
}

export const styles = ({
  isFocused,
  isReadOnly,
}: {
  isFocused: boolean
  isReadOnly: boolean
}): SystemStyleObject => {
  const borderColor = isReadOnly
    ? undefined
    : isFocused
    ? 'primary.500'
    : 'neutral.500'

  return {
    '.ProseMirror-focused': {
      outline: 'none',
    },
    '.ProseMenuBar': {
      borderRadius: '4px 4px 0px 0px',
      borderColor,
      borderWidth: '2px',
      p: 1,
    },
    '.ProseMirror': {
      borderColor,
      bgColor: 'white',
      overflowY: isReadOnly ? undefined : 'scroll',
      overflowWrap: 'break-word',
      borderStyle: 'solid',
      borderWidth: isReadOnly ? undefined : '0px 2px 2px 2px',
      borderRadius: isReadOnly ? undefined : '0px 0px 4px 4px',
      p: isReadOnly ? undefined : '8px',
      fontSize: 'sm',
      a: {
        color: 'neutral.700',
        textDecoration: 'underline',
        _hover: {
          color: 'primary.500',
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
      'li, ul': {
        paddingLeft: '0.5rem',
        marginLeft: '0.5rem',
      },
      'li, ol': {
        paddingLeft: '0.5rem',
        marginLeft: '0.5rem',
      },
      h1: TITLE_STYLE,
      h2: SUBTITLE_STYLE,
      h3: HEADER_STYLE,
    },
  }
}
