import {
  IsDefined,
  IsEmail,
  IsOptional,
  IsString,
  Matches,
  MaxLength,
} from 'class-validator'

import { CAMPAIGN_MAX_NAME_LEN, CAMPAIGN_NAME_REGEX } from '../constants'

export type GetCampaignDto = {
  id: number
  name: string
  userId: number
  defaultInstructions: string
  contactEmail: string | null
  publicKey: string
}

export class CreateCampaignReq {
  @IsDefined()
  @IsString()
  @Matches(CAMPAIGN_NAME_REGEX)
  @MaxLength(CAMPAIGN_MAX_NAME_LEN)
  name: string

  @IsDefined()
  @IsString()
  defaultInstructions: string

  @IsDefined()
  @IsString()
  publicKey: string
}

export class UpdateCampaignReq {
  @IsOptional()
  @IsString()
  @Matches(CAMPAIGN_NAME_REGEX)
  @MaxLength(CAMPAIGN_MAX_NAME_LEN)
  name?: string

  @IsOptional()
  @IsString()
  defaultInstructions?: string

  @IsOptional()
  @IsEmail()
  contactEmail?: string | null
}
