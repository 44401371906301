import { BiPaperPlane, BiPencil } from 'react-icons/bi'
import {
  ButtonGroup,
  ButtonGroupProps,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import { MAX_DOWLOAD_FILE_SIZE_IN_BYTES } from '~/constants/config'
import { DeleteButton } from '~/features/delete'
import { DownloadAllButton } from '~/features/download'

export const SubmissionActionButtons = ({
  submission,
  onShare,
  onEdit,
  documentSize,
  isDisabled = false,
  ...rest
}: {
  submission: GetSubmissionDto
  onShare: (s: GetSubmissionDto) => void
  onEdit: (s: GetSubmissionDto) => void
  documentSize: number
  isDisabled?: boolean
} & ButtonGroupProps) => {
  return (
    <ButtonGroup {...rest}>
      <Tooltip label="Share">
        <IconButton
          aria-label="share"
          fontSize="1.25rem"
          icon={<BiPaperPlane />}
          onClick={(e) => {
            e.stopPropagation()
            onShare(submission)
          }}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <Tooltip label="Edit">
        <IconButton
          aria-label="edit-instructions"
          fontSize="1.25rem"
          icon={<BiPencil />}
          onClick={() => onEdit(submission)}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <Tooltip
        label={
          documentSize > MAX_DOWLOAD_FILE_SIZE_IN_BYTES
            ? 'Your total file size exceeds 1GB. Please download the files individually to prevent system overload.'
            : 'Download all'
        }
      >
        <span>
          <DownloadAllButton
            campaignId={submission.campaignId}
            campaignName={submission.campaignName}
            campaignPublicKey={submission.campaignPublicKey}
            documents={submission.documents}
            isDisabled={
              isDisabled || documentSize > MAX_DOWLOAD_FILE_SIZE_IN_BYTES
            }
          />
        </span>
      </Tooltip>
      <Tooltip label="Delete all">
        <span>
          <DeleteButton submission={submission} isDisabled={isDisabled} />
        </span>
      </Tooltip>
    </ButtonGroup>
  )
}
