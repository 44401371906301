import { Box, Image, Link, Text, VStack } from '@chakra-ui/react'

import NotFound404 from '~/assets/NotFound404.svg'

interface ExpiredLinkInformationProps {
  submissionDate: string
  email: string | null
}

export const ExpiredLinkInformation = ({
  submissionDate,
  email,
}: ExpiredLinkInformationProps): JSX.Element => {
  const middleText = email ? (
    <>
      to <Link href={`mailto:${email}`}>{email}</Link>
    </>
  ) : (
    ''
  )

  return (
    <VStack justifyContent="center" alignItems="center" height="90vh">
      <VStack
        maxW={{ base: '100vw', md: '720px' }}
        spacing={10}
        p={{ base: 6, md: 10 }}
      >
        <Image src={NotFound404} alt="404 Not Found" />
        <VStack spacing={4}>
          <Text textStyle="h3" align="center">
            {`The link to submit documents `}
            {middleText}
            {` has expired on ${submissionDate}`}
          </Text>
          <Text textStyle="body-1" align="center">
            Please reach out to the requester for a new link.
          </Text>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default ExpiredLinkInformation
