import { Divider, Image, Spinner, Text, VStack } from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import FileHandoverSvg from '~/assets/FileHandover.svg'
import { useAdminAuth } from '~/features/auth'

import { useCampaignId } from '../hooks/campaigns.hooks'
import { useGetSubmissions } from '../hooks/submissions.hooks'

import { Submission, SubmissionHeader } from './Submission'

export const Submissions = ({
  onShare,
}: {
  onShare: (s: GetSubmissionDto) => void
}) => {
  const { adminUser } = useAdminAuth()
  const { campaignId } = useCampaignId()
  const { submissions, isSubmissionsLoading } = useGetSubmissions({
    userId: adminUser?.id,
    campaignId,
  })

  if (isSubmissionsLoading || !submissions) return <Spinner />

  if (submissions.length === 0)
    return (
      <VStack spacing={10} justify="center" p={{ base: 6, md: 20 }}>
        <VStack spacing={4}>
          <Text textStyle="h4">
            {campaignId
              ? `You don't have any links in this collection yet`
              : `You don't have any links yet`}
          </Text>
          <Text color="neutral.700">
            {campaignId
              ? `Start creating today!`
              : `To start sharing submission links, create or select a collection.`}
          </Text>
        </VStack>
        <Image src={FileHandoverSvg} alt="box-girl" maxW="60%" />
      </VStack>
    )

  return (
    <VStack align="stretch" spacing={6}>
      <VStack align="stretch" spacing={0} divider={<Divider />}>
        <SubmissionHeader />
        {submissions.map((s) => (
          <Submission key={s.id} submission={s} onShare={onShare} />
        ))}
      </VStack>
    </VStack>
  )
}
