import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  CreateCampaignReq,
  GetCampaignDto,
  UpdateCampaignReq,
} from '~shared/dtos'

import { adminQueryKeys } from '~/constants/query-keys'
import { useAdminAuth } from '~/features/auth'
import { api } from '~/lib/api'
import { ResponseError } from '~/types/error'

export const useGetCampaigns = (userId?: number) => {
  const { data, isLoading } = useQuery(
    adminQueryKeys.campaigns(userId),
    () =>
      api
        .url(`/users/${userId ?? ''}/campaigns`)
        .get()
        .json<GetCampaignDto[]>(),
    { enabled: !!userId, suspense: true },
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { campaigns: data!, isCampaignsLoading: isLoading }
}

export const useCreateCampaignMutation = (
  userId?: number,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (res: GetCampaignDto) => void
    onError?: (err: ResponseError) => void
  } = {},
) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (body: CreateCampaignReq): Promise<GetCampaignDto> => {
      return await api
        .url(`/users/${userId ?? ''}/campaigns`)
        .post(body)
        .json<GetCampaignDto>()
    },
    {
      onSuccess: async (res: GetCampaignDto) => {
        await queryClient.invalidateQueries(adminQueryKeys.campaigns(userId))
        onSuccess?.(res)
      },
      onError,
    },
  )
}

export const useUpdateCampaignMutation = (
  { campaignId, userId }: { campaignId?: number; userId?: number },
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (res: GetCampaignDto) => void
    onError?: () => void
  } = {},
) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (body: UpdateCampaignReq): Promise<GetCampaignDto> => {
      return await api
        .url(`/users/${userId ?? ''}/campaigns/${campaignId ?? ''}`)
        .patch(body)
        .json<GetCampaignDto>()
    },
    {
      onSuccess: async (res: GetCampaignDto) => {
        // TODO - should optimistically update instead
        await queryClient.invalidateQueries(adminQueryKeys.campaigns(userId))
        onSuccess?.(res)
      },
      onError,
    },
  )
}

export const useCampaignId = (): { campaignId: number } => {
  const { campaignId } = useParams()
  return { campaignId: Number(campaignId) }
}

// Composite hook that relies on URL param, useAuth(), and useGetCampaigns()
export const useCampaign = () => {
  const { adminUser } = useAdminAuth()
  const { campaignId } = useCampaignId()

  const { campaigns, isCampaignsLoading } = useGetCampaigns(adminUser?.id)

  // All campaigns
  if (Number.isNaN(campaignId))
    return { campaign: undefined, isCampaignLoading: false }

  return {
    campaign: campaigns?.find((c) => c.id === campaignId),
    isCampaignLoading: isCampaignsLoading,
  }
}
