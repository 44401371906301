import { BiCheck, BiCopy } from 'react-icons/bi'
import {
  Icon,
  IconButton,
  IconButtonProps,
  useClipboard,
} from '@chakra-ui/react'

export interface CopyFieldIdButtonProps extends IconButtonProps {
  stringToCopy: string
}

export const CopyButton = ({
  stringToCopy,
  ...iconButtonProps
}: CopyFieldIdButtonProps): JSX.Element => {
  const { onCopy, hasCopied } = useClipboard(stringToCopy)

  return (
    <IconButton
      w="2rem"
      size="sm"
      icon={
        hasCopied ? (
          <BiCheck fontSize="1.25rem" />
        ) : (
          <BiCopy fontSize="1.25rem" />
        )
      }
      onClick={(e) => {
        e.stopPropagation()
        onCopy()
      }}
      {...iconButtonProps}
    />
  )
}
