import { ReactElement } from 'react'
import {
  BiBold,
  BiItalic,
  BiListOl,
  BiListUl,
  BiStrikethrough,
  BiUnderline,
} from 'react-icons/bi'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  TypographyProps,
} from '@chakra-ui/react'
import { Editor } from '@tiptap/react'

import { HEADER_STYLE, SUBTITLE_STYLE, TITLE_STYLE } from '../styles'

import { LinkButton } from './LinkButton'

type MenuButtonTypes = FormatIconButton | DropdownMenuButton | Divider

type FormatIconButton = {
  type: 'icon'
  tooltipLabel: string
  onClick: (editor: Editor) => boolean
  Icon: ReactElement
  isActive: (editor: Editor) => boolean
}

type DropdownMenuButton = {
  type: 'dropdown'
  options: {
    label: string
    onClick: (editor: Editor) => boolean
    isActive: (editor: Editor) => boolean
    styles?: {
      fontWeight: TypographyProps['fontWeight']
      fontSize: TypographyProps['fontSize']
    }
  }[]
}

type Divider = {
  type: 'divider'
}

const menuButtons: MenuButtonTypes[] = [
  {
    type: 'icon',
    tooltipLabel: 'Bold (Ctrl + B)',
    onClick: (editor) => editor.chain().focus().toggleBold().run(),
    Icon: <BiBold />,
    isActive: (editor) => editor.isActive('bold'),
  },
  {
    type: 'icon',
    tooltipLabel: 'Italic (Ctrl + I)',
    onClick: (editor) => editor.chain().focus().toggleItalic().run(),
    Icon: <BiItalic />,
    isActive: (editor) => editor.isActive('italic'),
  },
  {
    type: 'icon',
    tooltipLabel: 'Underline',
    Icon: <BiUnderline />,
    onClick: (editor) => editor.commands.toggleUnderline(),
    isActive: (editor) => editor.isActive('underline'),
  },
  {
    type: 'icon',
    tooltipLabel: 'Strikethrough',
    onClick: (editor) => editor.chain().focus().toggleStrike().run(),
    Icon: <BiStrikethrough />,
    isActive: (editor) => editor.isActive('strike'),
  },
  {
    type: 'divider',
  },
  {
    type: 'dropdown',
    options: [
      {
        label: 'Title',
        onClick: (editor) =>
          editor.chain().focus().toggleHeading({ level: 1 }).run(),
        isActive: (editor: Editor) => editor.isActive('heading', { level: 1 }),
        styles: {
          fontSize: TITLE_STYLE.fontSize,
          fontWeight: TITLE_STYLE.fontWeight,
        },
      },
      {
        label: 'Subtitle',
        onClick: (editor) =>
          editor.chain().focus().toggleHeading({ level: 2 }).run(),
        isActive: (editor: Editor) => editor.isActive('heading', { level: 2 }),
        styles: {
          fontSize: SUBTITLE_STYLE.fontSize,
          fontWeight: SUBTITLE_STYLE.fontWeight,
        },
      },
      {
        label: 'Header',
        onClick: (editor) =>
          editor.chain().focus().toggleHeading({ level: 3 }).run(),
        isActive: (editor: Editor) => editor.isActive('heading', { level: 3 }),
        styles: {
          fontSize: HEADER_STYLE.fontSize,
          fontWeight: HEADER_STYLE.fontWeight,
        },
      },
      {
        label: 'Normal',
        onClick: (editor) => editor.chain().focus().clearNodes().run(),
        isActive: (_editor: Editor) => false,
      },
    ],
  },
  {
    type: 'divider',
  },
  {
    type: 'icon',
    tooltipLabel: 'Bullet List',
    Icon: <BiListUl />,
    onClick: (editor: Editor) =>
      editor.chain().focus().toggleBulletList().run(),
    isActive: (editor: Editor) => editor.isActive('bulletList'),
  },
  {
    type: 'icon',
    tooltipLabel: 'Ordered List',
    Icon: <BiListOl />,
    onClick: (editor: Editor) =>
      editor.chain().focus().toggleOrderedList().run(),
    isActive: (editor: Editor) => editor.isActive('orderedList'),
  },
]

const RichTextDivider = () => {
  return (
    <>
      <Box
        sx={{
          width: '1px',
          height: '1rem',
          backgroundColor: 'gray.300',
          mx: 4,
        }}
      />
    </>
  )
}

export const RichTextMenu = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null
  }

  return (
    <HStack p={1} className="ProseMenuBar">
      {menuButtons.map((node, index) => {
        if (node.type === 'divider') return <RichTextDivider key={index} />

        if (node.type === 'dropdown') {
          const currActiveStyle =
            node.options.find((option) => option.isActive(editor))?.label ??
            'Normal'

          return (
            <Menu matchWidth closeOnBlur closeOnSelect key={index}>
              <MenuButton
                colorScheme="secondary"
                textAlign="start"
                w="140px"
                variant="outline"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                textTransform="capitalize"
              >
                {currActiveStyle}
              </MenuButton>
              <MenuList>
                {node.options.map((option) => (
                  <MenuItem
                    key={option.label}
                    textTransform="capitalize"
                    onClick={() => option.onClick(editor)}
                    {...option.styles}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )
        }

        if (node.type === 'icon') {
          const { tooltipLabel, Icon, isActive, onClick } = node

          return (
            <Tooltip label={tooltipLabel} key={tooltipLabel}>
              <IconButton
                variant="clear"
                color="black"
                backgroundColor={isActive(editor) ? 'primary.100' : undefined}
                aria-label={tooltipLabel}
                onClick={() => onClick(editor)}
                borderColor="transparent"
                icon={Icon}
              />
            </Tooltip>
          )
        }
      })}

      <RichTextDivider />

      <LinkButton editor={editor} />

      <RichTextDivider />
    </HStack>
  )
}
