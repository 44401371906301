import { useMemo } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { WarningIcon } from '@chakra-ui/icons'
import { Box, Heading, Link, Spinner, Text, VStack } from '@chakra-ui/react'
import { Infobox } from '@opengovsg/design-system-react'

import { routes } from '~/constants/routes'
import { DocumentsUpload } from '~/features/upload/Upload/DocumentsUpload'
import { fmtDateMonthInLetters } from '~/utils/humanReadable'

import { RichTextEditor } from '../richtext'

import ExpiredLinkInformation from './components/ExpiredLinkInformation'
import {
  usePublicSubmission,
  useSubmissionPublicId,
  useUponPublicUpload,
} from './public.hooks'

export const UploadPage = (): JSX.Element => {
  const navigate = useNavigate()
  const { submissionPublicId } = useSubmissionPublicId()
  const { submission, isSubmissionLoading, isExpired } =
    usePublicSubmission(submissionPublicId)
  const uponPublicUpload = useUponPublicUpload(submissionPublicId)

  const expiryDate = useMemo(() => {
    return fmtDateMonthInLetters(submission?.expiresAt ?? null)
  }, [submission])

  if (isSubmissionLoading) return <Spinner />

  if (!submission) return <Navigate to={routes.public.error} />

  if (isExpired)
    return (
      <ExpiredLinkInformation
        submissionDate={expiryDate}
        email={submission.contactEmail}
      />
    )

  return (
    <Box justifyContent="center" alignItems="center">
      <VStack
        maxW={{ base: '100vw', md: '720px' }}
        spacing={10}
        p={{ base: 6, md: 10 }}
      >
        <VStack align="start" spacing={4}>
          {submission?.contactEmail ? (
            <Heading textAlign="left" as="h6" size="lg">
              {`You've received a request from `}
              <Link href={`mailto:${submission.contactEmail}`}>
                {submission.contactEmail}
              </Link>
              {` to submit your documents.`}
            </Heading>
          ) : (
            <Heading
              textAlign="left"
              as="h6"
              size="lg"
            >{`You've received a request to submit your documents.`}</Heading>
          )}
          <Infobox
            border="2px"
            borderColor="yellow.300"
            borderRadius="md"
            variant="warning"
            w="100%"
            icon={<WarningIcon color="yellow.400" fontSize="xl" />}
          >
            <Text
              textStyle="body-1"
              pt="2px"
            >{`Link expires on ${expiryDate}`}</Text>{' '}
            {/* Padding top to align nicer with icon*/}
          </Infobox>
          {submission.instructions && (
            <>
              <Text pt={6} textStyle="h5">
                Instructions
              </Text>
              <RichTextEditor isReadOnly content={submission.instructions} />
            </>
          )}
        </VStack>
        <DocumentsUpload
          w="full"
          submissionId={submissionPublicId}
          campaignPublicKey={submission.campaignPublicKey}
          onComplete={() => uponPublicUpload()}
        />
      </VStack>
    </Box>
  )
}
