import { ReactNode } from 'react'
import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import { useAdminAuth } from '~/features/auth'
import {
  useCampaignId,
  useGetCampaigns,
} from '~/features/dashboard/hooks/campaigns.hooks'

import { CreateCampaignModal } from './CreateCampaignModal'

export const CampaignSidebar = ({ children }: { children: ReactNode }) => {
  const { adminUser } = useAdminAuth()
  const navigate = useNavigate()
  const { campaignId } = useCampaignId()

  const { campaigns, isCampaignsLoading } = useGetCampaigns(adminUser?.id)

  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure()

  return (
    <Box bg="gray.100" w="full">
      <Flex align="stretch" minH="admin-content-min-height" w="full">
        <Box
          bg="grey.50"
          borderRight="1px"
          borderRightColor="base.divider.medium"
          w={{ base: 'full', md: 80 }}
        >
          <Flex py={10} pl={8} justifyContent="space-between">
            <Text textStyle="h4">Collections</Text>
          </Flex>
          {!campaigns || isCampaignsLoading ? (
            <Spinner alignSelf="center" />
          ) : (
            <VStack w="full" align="stretch" spacing={0}>
              {campaigns.map((c) => (
                <Button
                  w="full"
                  key={c.id}
                  variant="clear"
                  textAlign="start"
                  justifyContent="start"
                  textColor={
                    campaignId === c.id
                      ? 'interaction.main.default'
                      : 'interaction.support.unselected'
                  }
                  p={8}
                  bgColor={
                    campaignId === c.id
                      ? 'interaction.main-subtle.default'
                      : 'unset'
                  }
                  onClick={() => {
                    navigate(`/admin/collections/${c.id}`)
                  }}
                >
                  <Text isTruncated>{c.name}</Text>
                </Button>
              ))}
              <Button
                variant="clear"
                rightIcon={<BiPlus fontSize="20px" />}
                justifyContent="start"
                p={8}
                onClick={() => onCreateModalOpen()}
              >
                Create Collection
              </Button>
            </VStack>
          )}
        </Box>
        <Flex p={4} w="full" bgColor="white" minW={0}>
          {children}
        </Flex>
      </Flex>
      <CreateCampaignModal
        onClose={() => {
          onCreateModalClose()
        }}
        isOpen={isCreateModalOpen}
      />
    </Box>
  )
}
